import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
 
//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { ProductClass } from 'src/app/classes/product';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent implements OnInit {

  campaign: any;
  display_array: ProductClass[] = [];
  baseUrl = environment.serverUrl;
  displayedColumns: string[] = ['select', 'productname', 'category', 'advertiser','edit'];
  dataSource: any;
  selection = new SelectionModel<ProductClass>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient, private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,) {

    this.onReadProducts();
  }

  ngOnInit() {
   
    
  }

  /*ionViewWillEnter(){

    this.onReadProducts();
  }*/

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected products?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.selection.selected.forEach(prod => {          
          this.deleteProduct(prod.idNo);
        });
        
        this.selection.clear(); 
        this.alertService.success('Products successfully deleted')
      }
    });
  }

  async deleteProduct(productId)
  {
    this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/sales/product/delete_product.php?productId=${productId}&employee_no=0`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadProducts();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadProducts()
  {

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/product/read_products.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++) 
        {
          this.display_array[i] = {idNo: data[i].idNo,product_name: data[i].product_name,description: data[i].description,quantity: data[i].quantity,discount: data[i].discount,turnVat: data[i].turnVat,unit_price: data[i].unit_price,vat_rate: data[i].vat_rate,vat_type: data[i].vat_type,date_time: data[i].date_time,image: data[i].image,position: data[i].position,category: data[i].category,company: data[i].company,clientNo: data[i].clientNo};
        }

        this.dataSource = new MatTableDataSource<ProductClass>(this.display_array);
        this.dataSource.paginator = this.paginator;

      } ,error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  editProduct(productId){

    localStorage.setItem('productId', JSON.stringify(productId));
    this.router.navigate(['/home/view-product']);
  }
 
  addNewProduct(){
    this.router.navigate(['/home/new-product']);
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}