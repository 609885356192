import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';

import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';

import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {SelectItem} from 'primeng/api';
import {Location} from '@angular/common';

// Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';

// To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NavController} from '@ionic/angular';

// Toasta
import {Router, ActivatedRoute} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';


export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SeasonClass} from "../../classes/season";

const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-programme-list',
    templateUrl: './programme-list.component.html',
    styleUrls: ['./programme-list.component.scss'],
    styles: [`
        .filter-container {
            text-align: center;
        }

        .car-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2em;
            border-bottom: 1px solid #d9dad9;
        }

        .car-details > div {
            display: flex;
            align-items: center;
        }

        .car-details > div img {
            margin-right: 14px;
        }

        .car-detail {
            padding: 0 1em 1em 1em;
            border-bottom: 1px solid #d9dad9;
            margin: 1em;
        }

        .ui-panel-content {
            padding: 1em;
        }

        .dark-theme :host ::ng-deep .car-details,
        .dark-theme :host ::ng-deep .car-detail {
            border-bottom: 1px solid #191919;
        }

        @media (max-width: 1024px) {
            .car-details img {
                width: 75px;
            }

            .filter-container {
                text-align: left;
            }
        }
    `],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ProgrammeListComponent implements OnInit, AfterViewInit {

    constructor(private alertService: AlertService, route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder, private httpClient: HttpClient,
        private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,
        private _location: Location) {
this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
this.onChannels();
}

sortOptions: SelectItem[];
sortKey: string;
sortField: string;
sortOrder: number;

employee_no: number = 0;
selected_channels: number[] = [];
searchFormGroup: FormGroup;
channels: SareClass[] = [];

display_array: ProgrammeClass[] = []; 
display_season: SeasonClass[] = [];
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
displayedColumns: string[] = ['image', 'prog_name', 'length', 'language', 'genre', 'subgenre', 'category', 'distributor', 'contract_type', 'contract_number'];
displayedSeasonColumns: string[] = [ 'season', 'episodes', 'producer', 'director', 'View'];

dataSource = new MatTableDataSource<ProgrammeClass>([]);
dataSeasonSource = new MatTableDataSource(this.display_season);
expandedElement: ProgrammeClass | null;

ngOnInit() {

this.searchFormGroup = this.formBuilder.group({
    channelsCtrl: [this.selected_channels],
});

this.sortOptions = [
    {label: 'Newest First', value: '!prog_no'},
    {label: 'Oldest First', value: 'prog_no'},
    {label: 'Programme Name', value: 'prog_name'}
];

}

ngAfterViewInit() {
this.dataSource.paginator = this.paginator;
this.dataSource.sort = this.sort;
}

openDeleteDialog(event: Event, prog: ProgrammeClass): void {
const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    width: '400px',
    data: 'Are you sure you want to delete ' + prog.prog_name + ' ?'
});

dialogRef.afterClosed().subscribe(result => {
    if (result) {
        this.deleteProgramme(prog.prog_no);
    }
});
}

deleteProgramme(prog_no) {
const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/delete_programme.php`,
    {
        prog_no: prog_no

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            if (data.message === 'success') {
                this.alertService.success('Programme successfully deleted');
                this.onReadProgrammes();
            } else {
                this.alertService.error(data.message);
            }
        }
    );
}

selectProgramme(event: Event, prog: ProgrammeClass) {

localStorage.setItem('prog_no', JSON.stringify(prog.prog_no));
this.router.navigate(['/home/view-programme']);
}

onClickRow(expandedElement, element) { 

this.dataSeasonSource.data = element.season_data;
this.expandedElement = this.expandedElement === element ? null : element
}

async onChannels() {

const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
        channes: this.selected_channels,
        date: moment().format('YYYY/MM/DD'),
        employee_no: this.employee_no,

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            for (let i = 0; i < data.length; i++) {
                this.channels[i] = {
                    bdcr_no: data[i].bdcr_no,
                    genre: data[i].genre,
                    platform: data[i].platform,
                    sare_name: data[i].sare_name,
                    sare_no: data[i].sare_no
                };
            }
            this.selected_channels.push(this.channels[0].sare_no);
            this.onReadProgrammes();
        }
    );
}

async onReadProgrammes() {

const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
    {
        channes: this.selected_channels,
        employee_no: this.employee_no,

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            this.display_array = [];

            for (let i = 0; i < data.length; i++) {
                this.display_array[i] = {
                    genre: data[i].genre,
                    image: data[i].image,
                    language: data[i].language,
                    length: data[i].length,
                    prod_house: data[i].prod_house,
                    producer: data[i].producer,
                    prog_name: data[i].prog_name,
                    prog_no: data[i].prog_no,
                    bdcr_no: data[i].bdcr_no,
                    type: data[i].type,
                    category: data[i].category,
                    rcrd_no: data[i].rcrd_no,
                    repeat_name: data[i].repeat_name,
                    sare_no: data[i].sare_no,
                    subgenre: data[i].subgenre,
                    season_data: data[i].season_data,
                    genre_sentech: data[i].genre_sentech,
                    subgenre_sentech: data[i].subgenre_sentech,
                    contract_no: data[i].contract_no,
                    contract_type: data[i].contract_type,
                    distributors: data[i].distributors
                };
            }
 
            //this.dataSource = new MatTableDataSource<ProductClass>(this.display_array);
            //this.dataSource.paginator = this.paginator;

            this.dataSource.data = this.display_array;

        }
    ); 
}

applyFilter(event: Event) {
const filterValue = (event.target as HTMLInputElement).value;
this.dataSource.filter = filterValue.trim().toLowerCase();
}

back() {
this._location.back();
}

viewSeason(season, programme) {
localStorage.setItem('prog_no', JSON.stringify(programme));
localStorage.setItem('season_no', JSON.stringify(season));
this.router.navigate(['/home/view-season']);
}

    materialManagement() {
        this.router.navigate(['/home/material-management']);
    }

    newProgramme() {
        this.router.navigate(['/home/bulk-programme']);
    }
}
