import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import { saveAs } from 'file-saver';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { MaterialMedia } from 'src/app/classes/material.media';
import { SareClass } from 'src/app/classes/sare';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { SeasonClass } from 'src/app/classes/season';
import { EpisodeClass } from 'src/app/classes/episode';
import { SegmentClass } from 'src/app/classes/segment';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-view-asset',
  templateUrl: './view-asset.component.html',
  styleUrls: ['./view-asset.component.scss'],
})
export class ViewAssetComponent implements OnInit {

  userId: number;
  baseUrl = environment.serverUrl;

  password1: string = '';
  password2: string = '';
  changePassword: boolean = false;
  multiFormGroup: FormGroup;
  user: UserClass = new UserClass();


  //Multi form fields
  multi_materialID: string = '';
  multi_description: string = '';
  multi_asset_type: string= '';
  multi_asset_subtype: string = '';
  multi_channelGroup: number = 0;
  multi_title: any= '';
  multi_publication_date: string = '';
  multi_copyright: string = '';
  multi_tags: string = '';
  multi_collection: string = '';


  //Material
  audio_subtypes: string[] = ['Commentary','Discussion','Interview','Opening','Pitch','Speech','Theme Music','Voiceover'];
  document_subtypes: string[] = ['Billboard','Breaking News','Brief','Casting Announcement','Colouring Page','Copyright','Endboard Artwork','Fact Sheet','Final Social Media','Font File','Highlights','Legal Document','Listing','Media Alert','Press Clippings','Press Release','Schedule','Script','Subtitle File','Synopsis'];
  image_subtypes: string[] = ['Banner','Billboard','Colouring Page','Endboard','EPG','Final Digital','Final OOH Static','Final Print','Final Social Media','Header','Hero Image','Highlights','Holding Slide','IPI','Key Visual','Logo','Open File','Photograph','Poster','Press Clippings','Promo','Raw','Scan','Show Graphic','Show Poster','Show Poster','Stock Footage','Supersport Team Logo','Supersport Tournament Logo','Transparent Logo'];
  video_subtypes: string[] = ['Bumper','Clip','Commercial','Filler','Final Digital','Final Digital OOH','Final Social Media','Highlights','Promo','Sting','Stock Footage','Teaser','Trailer'];
  mult_asset_subtype: string[] = [];


  selected_channels: number[] = [];
  employee_no: number = 0;
  channels: SareClass[] = [];
  madia_ids: string[] = [];

  media_files: MaterialMedia[];
	responsiveOptions;

  //FTP
  ftpHost = 'ftp.mmsafrica.co.za';
  ftpUsername = 'mojaftp@mmsafrica.co.za';
  ftpPassword = 'pfnmms#2022';
  material_folder = '/Ftp-In/';

  //Programme
  prog_no: number = 0;
  season_no: number = 0;
  episode_no: number = 0;
  segment_no: number = 0;

  season_array: SeasonClass[] = [];
  episode_array: EpisodeClass[] = [];
  segments_array: SegmentClass[] = [];

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.userId = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    let strids = JSON.parse(localStorage.getItem('madia_ids'));
    this.multi_title = JSON.parse(localStorage.getItem('prog_name'));
    this.madia_ids = strids.split(";");
    this.refresh();

    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  }

  refresh(){
    this.prog_no = parseInt(JSON.parse(localStorage.getItem('prog_no')));
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onReadSeasons(this.prog_no);
    this.onChannels();
  }

  ngOnInit() {
 
    this.multiFormGroup = this.formBuilder.group({ 
      multi_materialIDCtrl: [this.multi_materialID],
      multi_descriptionCtrl: [this.multi_description],
      multi_asset_typeCtrl: [this.multi_asset_type],
      multi_asset_subtypeCtrl: [this.multi_asset_subtype],
      multi_channelGroupCtrl: [this.multi_channelGroup],
      multi_titleCtrl: [this.multi_title],
      multi_publication_dateCtrl: [this.multi_publication_date],
      multi_tagsCtrl: [this.multi_tags],
      multi_copyrightCtrl: [this.multi_copyright],
      multi_collectionCtrl: [this.multi_collection],
    });
  }


  ionViewWillEnter(){

    this.refresh();
  }

  async onReadSeasons(progNo)
  {
    this.prog_no = progNo;
    
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: 0,
        prog_no: this.prog_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.season_array = [];
        this.episode_array = [];
        if(this.episode_no <= 0 || this.episode_no == null){this.season_no = 0;}

        for(var i=0;i<data.length;i++)
        {
            this.season_array[i] = {actors: data[i].actors,directors: data[i].directors,producer: data[i].producer,cost_per_min: data[i].cost_per_min,end_date: data[i].end_date,episodes: data[i].episodes,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),run_no: data[i].run_no,seas_no: Number(data[i].seas_no),segments: data[i].segments,sst_date: data[i].sst_date,year_of_release: data[i].year_of_release,censorship: '',pg: '',synopsis: '',hashtags: data[i].hashtags};
        }
      } 
    )
  }

  async onReadEpisodes(season_no)
  {
    this.season_no = season_no;

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_episodes.php`,
    {
        employee_no: 0,
        prog_no: this.prog_no,
        seasonNo: season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.episode_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.episode_array[i] = {epi_len: data[i].epi_len,seas_no: Number(data[i].seas_no),title: data[i].title,b: data[i].b,break_price: data[i].break_price,d: data[i].d,day: data[i].day,epi_date: data[i].epi_date,epi_no: Number(data[i].epi_no),exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,n: data[i].n,nl: data[i].nl,omni_epi_no: data[i].omni_epi_no,p: data[i].p,pg: data[i].pg,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),rate_code: data[i].rate_code,replaced: data[i].replaced,run_no: data[i].run_no,s: data[i].s,synopsis: data[i].synopsis,v: data[i].v,material: data[i].material,segments_data: data[i].segments_data};
        }

      } 
    )
  }

  async onReadSegments(episode_no)
  {
    this.episode_no = episode_no;
    
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_segments.php`,
    {
        employee_no: 0,
        prog_no: this.prog_no,
        seasonNo: this.season_no,
        episode_no: episode_no

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.segments_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.segments_array[i] = {brek_len: data[i].brek_len,brek_time: data[i].brek_time,epis_no: data[i].epis_no,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,omni_epi_no: data[i].omni_epi_no,prog_no: data[i].prog_no,run_no: data[i].run_no,seas_no: data[i].seas_no,segm_len: data[i].segm_len,segm_no: data[i].segm_no,sst_time: data[i].sst_time};
        }

      } 
    )
  }

  setMaterialID(){

    for (let i = 0; i < this.media_files.length; i++) {

      let matID = this.multi_title.replaceAll('-','/')+'_S'+this.media_files[i].season_no+'E'+this.media_files[i].episode_no+'_P'+this.media_files[i].segment_no;
      this.media_files[i].material_id = matID;
    }
  }

  async onChannels() {

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/get_channels.php`,
        {
            channes: this.selected_channels,
            date: '',
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                for (let i = 0; i < data.length; i++) {
                    this.channels[i] = {
                        bdcr_no: data[i].bdcr_no,
                        genre: data[i].genre,
                        platform: data[i].platform,
                        sare_name: data[i].sare_name,
                        sare_no: data[i].sare_no
                    };
                }
                this.selected_channels.push(this.channels[0].sare_no);
                this.onReadMaterialFiles();
            }
        );
    }

  async onReadMaterialFiles() { 

    let strIDS = '';
    for(var i=0;i < this.madia_ids.length;i++){
      if(this.madia_ids[i] > "0"){
        strIDS = strIDS+this.madia_ids[i]+',';
      }
    }

    strIDS = strIDS+'0';
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/material/read_materials_files.php`,
        {
            channel: this.selected_channels,
            employee_no: this.employee_no,
            madia_ids: strIDS,
            display: true,
            ftpHost: this.ftpHost,
            ftpUsername: this.ftpUsername,
            ftpPassword: this.ftpPassword,
            dir: this.material_folder,
            name: 'Material'
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                this.media_files = [];
    
                for (let i = 0; i < data.length; i++) {

                    let asset_subtype = [];
                    let material_id = data[i].material_id;
                    if(material_id == "" || material_id == null){material_id  = this.multi_title;}

                    if(data[i].type == 'Image'){
                      asset_subtype = this.image_subtypes;
                    }
                    else
                    if(data[i].type == 'Video'){
                      asset_subtype = this.video_subtypes;
                    }
                    else
                    if(data[i].type == 'Audio'){
                      asset_subtype = this.audio_subtypes;
                    }
                    else
                    if(data[i].type == 'Document'){
                      asset_subtype = this.document_subtypes;
                    }

                    this.media_files[i] = {
                        channel: data[i].channel,date_added: data[i].date_added,duration: data[i].duration,employee_no: data[i].employee_no,file_name: data[i].file_name,idNo: data[i].idNo,image: data[i].image,material_id: material_id,prog_no: data[i].prog_no,publication_date: data[i].publication_date,size: data[i].size,status: data[i].status,subtype: data[i].subtype,type: data[i].type,file_format: data[i].file_format,selected: true,collection: data[i].collection,copyright: data[i].copyright,description: data[i].description,tags: data[i].tags,subtypeArray: asset_subtype,base64: data[i].base64,episode_no: data[i].episode_no,season_no: data[i].season_no,segment_no: data[i].segment_no};

                        this.multi_channelGroup = this.media_files[i].channel;
                }
            }
        );
    }

    getSubtypes(type,idNo){

      for (let i = 0; i < this.media_files.length; i++) {

        if(this.media_files[i].idNo == idNo){

            let asset_subtype = [];

            if(this.media_files[i].type == 'Image'){
              this.media_files[i].subtypeArray = this.image_subtypes;
            }
            else
            if(this.media_files[i].type == 'Video'){
              this.media_files[i].subtypeArray = this.video_subtypes;
            }
            else
            if(this.media_files[i].type == 'Audio'){
              this.media_files[i].subtypeArray = this.audio_subtypes;
            }
            else
            if(this.media_files[i].type == 'Document'){
              this.media_files[i].subtypeArray = this.document_subtypes;
            }
        }
      }
    }

    getMultSubtypes(type){

      this.mult_asset_subtype = [];

      if(type == 'Image'){
        this.mult_asset_subtype = this.image_subtypes;
      }
      else
      if(type == 'Video'){
        this.mult_asset_subtype = this.video_subtypes;
      }
      else
      if(type == 'Audio'){
        this.mult_asset_subtype = this.audio_subtypes;
      }
      else
      if(type == 'Document'){
        this.mult_asset_subtype = this.document_subtypes;
      }
    }

  async saveMetadata(){ 

    let error = '';

    for (let i = 0; i < this.media_files.length; i++) {

      //Material ID
      if(this.media_files[i].material_id == '' || this.media_files[i].material_id == null){
        error = 'Please enter material ID';
      }

      //Asset Type
      if(this.media_files[i].type == '' || this.media_files[i].type == null){
        
        if(this.multi_asset_type == '' || this.multi_asset_type == null){
          error = 'Please enter asset type';
        }
        else{
          this.media_files[i].type = this.multi_asset_type;
        }
      }
      
      //Sub-Type
      if(this.media_files[i].subtype == '' || this.media_files[i].subtype == null){

        if(this.multi_asset_subtype== '' || this.multi_asset_subtype == null){
          error = 'Please enter asset sub-type';
        }
        else{
          this.media_files[i].subtype = this.multi_asset_subtype;
        }
      }

      //channel Group
      if(this.media_files[i].channel == 0 || this.media_files[i].channel == null){
        
        if(this.multi_channelGroup == 0 || this.multi_channelGroup == null){
          error = 'Please add channel group';
        }
        else{
          this.media_files[i].channel = Number(this.multi_channelGroup);
        }
      }

      //Publication date
      if(this.multi_publication_date != '' && this.multi_publication_date != null){
        var date = moment(this.multi_publication_date.toString()).format("YYYY/MM/DD");
        this.media_files[i].publication_date = date; 
      }

      //collection
      if(this.multi_collection != '' && this.multi_collection != null){
        this.media_files[i].collection = this.multi_collection; 
      }

      //Copyright
      if(this.multi_copyright != '' && this.multi_copyright != null){
        this.media_files[i].copyright = this.multi_copyright; 
      }

      //Description
      if(this.multi_description != '' && this.multi_description != null){
        this.media_files[i].description = this.multi_description; 
      }

      //Tags
      if(this.multi_tags != '' && this.multi_tags != null){
        this.media_files[i].tags = this.multi_tags; 
      }
    }

    if(error == ''){
      
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/material/update_asset_metadata.php`,
    {
      userId: this.userId,
      multi_title: this.multi_title,
      assets: this.media_files

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success("Assets metadata successfully saved");
          this.router.navigate(['/home/programme-assets']);

        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }
  else{
    this.alertService.error("Something went wrong, Try again");
  }
  }

  openDeleteCampaignDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete file ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.deleteSelection();
      }
    });
  }

  async deleteSelection(){ 

    let error = '';

    for (let i = 0; i < this.media_files.length; i++) {
      this.media_files[i].selected = true;
    }

    if(error == ''){
      
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/material/delete_asset_files.php`,
    {
      userId: this.employee_no,
      assets: this.media_files,
      ftpHost: this.ftpHost,
      ftpUsername: this.ftpUsername,
      ftpPassword: this.ftpPassword,
      dir: this.material_folder,
      name: 'Material'

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success("Files successfully deleted");
          this.router.navigate(['/home/programme-assets']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }
  else{
    this.alertService.error("Something went wrong, Try again");
  }
  }

  async openDocument(file_name)
  {
    
    let file = this.material_folder+'Material/'+file_name;

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/filemanager/get_file_from_server.php`,
    {
      employee_no: this.employee_no,
      file_name: file,
      ftpHost: this.ftpHost,
      ftpUsername: this.ftpUsername,
      ftpPassword: this.ftpPassword

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
        
        if(data.message == 'success')
        {
          
          var files = data.download_files.split(";");

          for(var i=0;i<(files.length-1);i++)
          {
            var fileName = files[i].split("/")[1];
            this.export(files[i]).subscribe(data => saveAs(data, fileName)); 
          }
        }
        else
        {
          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
  }

  export(name) {
    return this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/filemanager/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}