import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/app/services/alert/alert.service';
import {Location} from '@angular/common';
import { WebArticleClass } from 'src/app/classes/web.article';
import { ArticleAuthor } from 'src/app/classes/article.author';
import * as $ from "jquery";

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { FTPFiles } from 'src/app/classes/ftp.files';
import { VideoProcessingService } from '../new-article/video-processing-service';
import { DomSanitizer } from '@angular/platform-browser';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss'],
  providers: [MessageService]
})
export class EditArticleComponent implements OnInit {

  formData = new FormData();
  author_list: ArticleAuthor[] = [];
  uploadedFiles: any[] = [];
  popularOptions: any[] = [{label: 'Not Popular', value: 'Not Popular'}, {label: 'Popular', value: 'Popular'}];
  statusOptions: any[] = [{label: 'Draft', value: 'Draft'}, {label: 'Publish Now', value: 'Publish Now'}, {label: 'Auto Publish', value: 'Auto Publish'}];

  videoOptions: any[] = [{label: 'Youtube Video', value: 'Youtube Video'}, {label: 'Upload Video', value: 'Upload Video'}];
  videoType = {label: 'Youtube Video', value: 'Youtube Video'};
  selectedFile: FileList  = null;

  public thumbnailData: string;
  public video_file = null;

  safeUrl;
  disp_youtbe = false;

  selectedImage: File = null;
  employee_no: number = 0;
  articleId: number = 0;
  articleFormGroup: FormGroup;
  article: WebArticleClass  = new WebArticleClass();

  constructor(private messageService: MessageService,private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,public dialog: MatDialog,private _location: Location,private videoService: VideoProcessingService,private sanitizer: DomSanitizer) {

    this.articleId = parseInt(JSON.parse(localStorage.getItem('articleId')));
    this.article.status = {label: 'Draft', value: 'Draft'};
   }

  ngOnInit() {

    this.articleFormGroup = this.formBuilder.group({
      headlineCtrl: [this.article.headline],
      slugCtrl: [this.article.slug],
      tagCtrl: [this.article.tag],
      summary_pull_quoteCtrl: [this.article.summary_pull_quote],
      post_contentCtrl: [this.article.post_content],
      imageCtrl: [this.article.image],
      videoCtrl: [this.article.video],
      videoTypeCtrl: [this.videoType],
      //twitter_embadedCtrl: [this.article.twitter_embaded],
      article_colorCtrl: [this.article.article_color],
      //is_popularCtrl: [this.article.is_popular],
      date_publishedCtrl: [this.article.date_published],
      time_publishedCtrl: [this.article.time_published],
      statusCtrl: [this.article.status],
      //article_byCtrl: [this.article.article_by],
      image_captionCtrl: [this.article.image_caption],
      image_creditCtrl: [this.article.image_credit],
      text_over_colorCtrl: [this.article.text_over_color],
      featured_homepageCtrl: [this.article.featured_homepage],
      featured_popularCtrl: [this.article.featured_popular],
      featured_subscetionCtrl: [this.article.featured_subscetion],
      authorCtrl: [this.article.author],
      guest_authorCtrl: [this.article.guest_author],
      categoryCtrl: [this.article.category],
      //auto_publishCtrl: [this.article.auto_publish],
    });

    this.onReadAuthors();
  }


  getTrustedUrl(url:any){ 
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }

  async onReadArticles(){
 
    var start_date = moment().format("YYYY/MM/DD");
    var end_date = moment().format("YYYY/MM/DD");
    
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/web-admin/read_one_articles.php`,
    {
      articleId: this.articleId,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

              this.article.idNo= data[0].idNo;
              this.article.article_by= data[0].article_by;
              this.article.article_color= data[0].article_color;
              this.article.auto_publish= data[0].auto_publish;
              this.article.category= data[0].category;
              this.article.date_created= data[0].date_created;
              this.article.date_modified= data[0].date_modified;
              this.article.headline= data[0].headline;
              this.article.image= data[0].image;
              //this.article.is_popular= data[0].is_popular;
              this.article.likes= data[0].likes;
              this.article.post_content= data[0].post_content;
              this.article.slug= data[0].slug;
              this.article.status= data[0].status;
              this.article.summary_pull_quote= data[0].summary_pull_quote;
              this.article.tag= data[0].tag;
              this.article.time_created= data[0].time_created;
              this.article.time_modified= data[0].time_modified;
              this.article.twitter_embaded= data[0].twitter_embaded;
              this.article.userId= data[0].userId;
              this.article.video= data[0].video;
              this.article.video_file= data[0].video_file;
              this.article.views= data[0].views;
              this.article.category= data[0].category;
              this.article.image_caption= data[0].image_caption;
              this.article.image_credit= data[0].image_credit;
              this.article.text_over_color= data[0].text_over_color;
              this.article.featured_homepage= data[0].featured_homepage;
              this.article.featured_popular= data[0].featured_popular;
              this.article.featured_subscetion= data[0].featured_subscetion;
              this.article.guest_author= data[0].guest_author;
              this.article.author= data[0].author;
              this.video_file = data[0].video_file; 

              this.disp_youtbe = false;
              if(this.article.video.length > 10){
                this.getTrustedUrl(this.article.video); 
                this.disp_youtbe = true;

                this.videoType = {label: 'Youtube Video', value: 'Youtube Video'};
              }
              else
              if(this.article.video_file.length > 10){
                this.getTrustedUrl(this.article.video_file);
                this.disp_youtbe = true;

                this.videoType = {label: 'Upload Video', value: 'Upload Video'};
              }


              if(data[0].status == "Draft"){

                this.article.date_published = moment().format("YYYY-MM-DD");
                this.article.time_published = moment().format("HH:mm");
              }
              else{

                this.article.time_published= String(data[0].date_published).substring(11);
                this.article.date_published= String(data[0].date_published).substring(0,10);
              }
              

              this.article.status = {label: 'Draft', value: 'Draft'};
              if(data[0].status == "Published"){
                this.article.status = {label: 'Publish Now', value: 'Publish Now'};
              }
              else
              if(data[0].status == "Waiting"){
                this.article.status = {label: 'Auto Publish', value: 'Auto Publish'};
              }
      }  
    ) 
  }
  
  reloadVideo(url){

    this.article.video = url.replace("watch?v=","embed/");

    this.disp_youtbe = false;
    if(this.article.video.length > 10){
      this.getTrustedUrl(this.article.video);
      this.disp_youtbe = true;
    }
  }

  changeVideoType(){

    this.disp_youtbe = false;
    
    if(this.videoType.value == 'Youtube Video'){

      if(this.article.video.length > 10){
        this.getTrustedUrl(this.article.video);
        this.disp_youtbe = true;
      }
    }
    else{

      if(this.article.video_file.length > 10){
        this.getTrustedUrl(this.article.video_file);
        this.disp_youtbe = true;

        this.videoType = {label: 'Upload Video', value: 'Upload Video'};
      }
    }
  }


  async replaceVideo(){ 

    this.formData.append('slug',<any>this.article.slug);
    this.formData.append('idNo',<any>this.article.idNo);
    this.formData.append('video_url',<any>this.article.video_file);
    if(this.video_file != null){this.formData.append('video_file',<any>this.video_file);}

    this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/web-admin/zimoja/replace_video.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      

      if(data.message == 'success')
      {
        this.alertService.success("Video successfully replaced"); 
        this.disp_youtbe = false;
        this.onReadArticles();
      }
      else{

        this.alertService.error(data.message);
      } 

    });
  }
 
  async saveArticle(){ 

    var strPublishDate = moment(this.article.date_published.toString()).format("YYYY-MM-DD");

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/web-admin/edit_article.php`,
    {
      articleId: this.articleId,
      headline: this.article.headline,
      slug: this.article.slug,
      tag: this.article.tag,
      summary_pull_quote: this.article.summary_pull_quote,
      post_content: this.article.post_content,
      image: this.article.image,
      video: this.article.video,
      twitter_embaded: this.article.twitter_embaded,
      article_color: this.article.article_color,
      //is_popular: this.article.is_popular,
      date_published: strPublishDate,
      time_published: this.article.time_published,
      auto_publish: this.article.auto_publish,
      status: this.article.status.value,
      article_by: this.article.article_by,
      category: this.article.category,
      image_caption: this.article.image_caption,
      image_credit: this.article.image_credit,
      text_over_color: this.article.text_over_color,
      featured_homepage: this.article.featured_homepage,
      featured_popular: this.article.featured_popular,
      featured_subscetion: this.article.featured_subscetion,
      author: this.article.author,
      guest_author: this.article.guest_author,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => { 
         
        if(data.message == 'success')
        {
          this.alertService.success("Article successfully saved");
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReplaceDefaultImage(event)
  {
    this.selectedImage = <File>event.target.files[0];

    const formData = new FormData();
    formData.append('file',this.selectedImage,this.selectedImage.name);
    formData.append('articleId',<any>this.articleId);
    formData.append('image_url',<any>this.article.image);

    this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/web-admin/zimoja/upload_image.php',formData).subscribe((message:any) => {
      
     
      if(message.message == 'success')
      {
        this.onReadArticles(); 
      }
      else
      { 
        this.alertService.error(message.message);
      }

    },error => {
        this.alertService.error("Something went wrong, Try again");
    });
  }

  async deleteImage()
  {
    this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/web-admin/zimoja/delete_image.php?articleId=${this.articleId}&image_url=${this.article.image}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadArticles();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async deleteVideo()
  {
    this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/web-admin/zimoja/delete_video.php?articleId=${this.articleId}&video_url=${this.article.video_file}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadArticles();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  public add(): void {
    this.videoService.promptForVideo().then(videoFile => { 
      //console.dir(videoFile);
      this.video_file = videoFile;
      return this.videoService.generateThumbnail(videoFile);
    }).then(thumbnailData => {
      //console.dir(thumbnailData); 
      this.thumbnailData = thumbnailData;
      this.replaceVideo();
    })
  }

  async onReadAuthors() {

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/web-admin/zimoja/read_authors.php`,
        {
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                this.author_list = [];
    
                for (let i = 0; i < data.length; i++) {
                    this.author_list[i] = {Bio: data[i].Bio,Bio_Summary: data[i].Bio_Summary,Collection_ID: data[i].Collection_ID,Created_On: data[i].Created_On,Email: data[i].Email,Facebook_Profile_Link: data[i].Facebook_Profile_Link,Instagram_Profile_Link: data[i].Instagram_Profile_Link,Item_ID: data[i].Item_ID,Name: data[i].Name,Picture: data[i].Picture,Published_On: data[i].Published_On,Slug: data[i].Slug,Twitter_Profile_Link: data[i].Twitter_Profile_Link,Updated_On: data[i].Updated_On
                    };
                }
    
                this.onReadArticles();
            }
        ); 
    }

    selectColor(color)
    {
      this.article.article_color = color;
    }
  
    selectTextColor(color)
    {
      this.article.text_over_color = color;
    }

  back(){
    this._location.back();
  }

}
