import {Component, OnInit, ViewChild} from '@angular/core';
import {SponsorshipSpotModel} from '../../models/sponsorship-spot.model';
import {SponsorshipService} from '../../services/sponsorship/sponsorship.service';
import {SponsorshipSpotService} from '../../services/sponsorship-spot/sponsorship-spot.service';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service'; 
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {SelectionModel} from '@angular/cdk/collections';
import {FlightingCodeService} from '../../services/flighting-code/flighting-code.service';
import {AllocateFlightingCodeDialogComponent} from '../allocate-flighting-code-dialog/allocate-flighting-code-dialog.component';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {EditCampaignSpotDialogComponent} from '../edit-campaign-spot-dialog/edit-campaign-spot-dialog.component';
import {EditSponsorshipSpotDialogComponent} from '../edit-sponsorship-spot-dialog/edit-sponsorship-spot-dialog.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { SponsorshipClass } from 'src/app/classes/sponorship';
import { FormGroup, Validators,FormBuilder,FormControl } from '@angular/forms';
import { ClientClass } from 'src/app/classes/client';
import { ProductClass } from 'src/app/classes/product';
import { ProgrammeClass } from 'src/app/classes/programme';

@Component({
  selector: 'app-sponsorship-spots',
  templateUrl: './sponsorship-spots.component.html',
  styleUrls: ['./sponsorship-spots.component.scss']
})
export class SponsorshipSpotsComponent implements OnInit {
  sponsorship: any;
  sponsorshipSpots: SponsorshipSpotModel[];
  displayedColumns: string[] = ['select', 'element', 'duration', 'rate', 'breakScheduleDate', 'industryCode',
    'rateCardTotal', 'discount', 'sponsorshipCost'];
  baseUrl = environment.serverUrl;
  dataSource: any;
  selection = new SelectionModel<SponsorshipSpotModel>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  sponsorshipFormGroup: FormGroup;
  sponsorship_class: SponsorshipClass = new SponsorshipClass();

  display_array: ClientClass[] = [];
  products_array: ProductClass[] = [];
  agency_array: ClientClass[] = [];
  programmes_array: ProgrammeClass[] = [];

  constructor(private sponsorshipService: SponsorshipService, private sponsorshipSpotService: SponsorshipSpotService,
              private router: Router, private  alertService: AlertService, public dialog: MatDialog,
              private flightingCodeService: FlightingCodeService,private _location: Location,private httpClient: HttpClient,private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.sponsorship = this.sponsorshipService.getCreatedSponsorship();
    if (this.sponsorship) {
      this.getSponsorships();
    } else {
      this.sponsorship = JSON.parse(localStorage.getItem('selectedSponsorship'));
      if (this.sponsorship && this.sponsorship.sponsorshipId) {
        this.getSponsorships();
      } else {
        this.returnToDashboard();
      }
    }

    this.sponsorshipFormGroup = this.formBuilder.group({
      spons_noCtrl: [this.sponsorship_class.spons_no, Validators.required],
      prog_noCtrl: [this.sponsorship_class.prog_no, Validators.required],
      prod_codeCtrl: [this.sponsorship_class.prod_code, Validators.required],
      sare_noCtrl: [this.sponsorship_class.sare_no],
      no_of_elementsCtrl: [this.sponsorship_class.no_of_elements, Validators.required],
      total_nominal_priceCtrl: [this.sponsorship_class.total_nominal_price],
      sst_dateCtrl: [this.sponsorship_class.sst_date, Validators.required],
      end_dateCtrl: [this.sponsorship_class.end_date, Validators.required],
      disc_percentCtrl: [this.sponsorship_class.disc_percent],
      pers_codeCtrl: [this.sponsorship_class.pers_code],
      owner_codeCtrl: [this.sponsorship_class.owner_code, Validators.required],
      agency_codeCtrl: [this.sponsorship_class.agency_code, Validators.required],
      rcrd_noCtrl: [this.sponsorship_class.rcrd_no]});

      this.onReadOneSponsorship();
      this.onReadProducts();
      this.onReadAgencies();
      this.onReadAdvertisers();
  }

  async saveSponsorship(){ 

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/update_sponsorship.php`,
    {
      spons_no: this.sponsorship_class.spons_no,
      prog_no: this.sponsorship_class.prog_no,
      prod_code: this.sponsorship_class.prod_code,
      sare_no: this.sponsorship_class.sare_no,
      no_of_elements: this.sponsorship_class.no_of_elements,
      end_date: this.sponsorship_class.end_date,
      owner_code: this.sponsorship_class.owner_code,
      agency_code: this.sponsorship_class.agency_code,
      sst_date: this.sponsorship_class.sst_date,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.onReadOneSponsorship();
          this.alertService.success("Sponsorship successfully saved");
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }


  async onReadOneSponsorship()
  {
    this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/sales/get_sponsorship.php?sponsorshipId=${this.sponsorship.sponsorshipId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.sponsorship_class.spons_no = Number(data[0].spons_no);
        this.sponsorship_class.prog_no = Number(data[0].prog_no);
        this.sponsorship_class.prod_code = Number(data[0].prod_code);
        this.sponsorship_class.sare_no = Number(data[0].sare_no);
        this.sponsorship_class.no_of_elements = Number(data[0].no_of_elements);
        this.sponsorship_class.total_nominal_price = Number(data[0].total_nominal_price);
        this.sponsorship_class.sst_date = data[0].sst_date;
        this.sponsorship_class.end_date = data[0].end_date;
        this.sponsorship_class.disc_percent = Number(data[0].disc_percent);
        this.sponsorship_class.pers_code = Number(data[0].pers_code);
        this.sponsorship_class.owner_code = Number(data[0].owner_code);
        this.sponsorship_class.agency_code = Number(data[0].agency_code);
        this.sponsorship_class.rcrd_no = data[0].rcrd_no;

        this.onReadProgrammes(this.sponsorship_class.sare_no);

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
  }

  async onReadProgrammes(sare_no) {

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
    {
        channes: [sare_no],
        employee_no: 0,

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            this.programmes_array = [];

            for (let i = 0; i < data.length; i++) {
                this.programmes_array[i] = {
                    genre: data[i].genre,
                    image: data[i].image,
                    language: data[i].language,
                    length: data[i].length,
                    prod_house: data[i].prod_house,
                    producer: data[i].producer,
                    prog_name: data[i].prog_name,
                    prog_no: data[i].prog_no,
                    bdcr_no: data[i].bdcr_no,
                    type: data[i].type,
                    category: data[i].category,
                    rcrd_no: data[i].rcrd_no,
                    repeat_name: data[i].repeat_name,
                    sare_no: data[i].sare_no,
                    subgenre: data[i].subgenre,
                    genre_sentech: data[i].genre_sentech,
                    subgenre_sentech: data[i].subgenre_sentech
                    ,season_data: data[i].season_data,
                    contract_no: data[i].contract_no,
                    contract_type: data[i].contract_type,
                    distributors: data[i].distributors
                };
            }

        }
    );
}

  async onReadAgencies()
  {
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/agency/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.agency_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.agency_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  async onReadProducts()
  {
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/product/read_products.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.products_array = [];

        for(var i=0;i<data.length;i++) 
        {
          this.products_array[i] = {idNo: data[i].idNo,product_name: data[i].product_name,description: data[i].description,quantity: data[i].quantity,discount: data[i].discount,turnVat: data[i].turnVat,unit_price: data[i].unit_price,vat_rate: data[i].vat_rate,vat_type: data[i].vat_type,date_time: data[i].date_time,image: data[i].image,position: data[i].position,category: data[i].category,company: data[i].company,clientNo: data[i].clientNo};
        }

      } ,error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadAdvertisers()
  {
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/client/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.display_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  getSponsorships() {
    this.sponsorshipSpotService.getSponsorshipSpots(this.sponsorship.sponsorshipId)
      .subscribe(sponsorshipSpots => {
        this.sponsorshipSpots = sponsorshipSpots;
        this.dataSource = new MatTableDataSource<SponsorshipSpotModel>(sponsorshipSpots);
        this.dataSource.paginator = this.paginator;
      }, error => {
        this.alertService.error("Something went wrong, Try again");
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AllocateFlightingCodeDialogComponent, {
      width: '600px',
      data: {flightingCode: '', sponsorshipSpots: this.selection.selected, productId: this.sponsorship.productId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.selection.selected) {
        this.selection.selected.forEach(spot => spot.industryCode = result.flightingCode);
        this.flightingCodeService.saveFlightingCodesForSponsorship(result).subscribe(success =>
          this.alertService.success('Code successfully allocated'), error => this.alertService.error('Code not saved'));
        this.selection.clear();
      }
    });
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(EditSponsorshipSpotDialogComponent, {
      width: '600px',
      data: new CampaignSpotModel()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.selection && result) {
        this.setEditedValues(result);
       
        // this.sponsorshipSpotService.editSpots(this.selection.selected).subscribe(success =>
        //  this.alertService.success('Spots successfully updated'), error => this.alertService.error('Spot changes not saved'));
      }
    });
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected spots?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let spotsToDelete = [];
        this.selection.selected.forEach(spot => {
          spotsToDelete.push(spot.elementId);
          const index = this.sponsorshipSpots.indexOf(spot, 0);
          this.sponsorshipSpots.splice(index, 1);
        });

        this.selection.clear();
        this.dataSource.data = this.sponsorshipSpots;
        this.table.renderRows();
        // this.campaignSpotService.deleteSpot(spotsToDelete).subscribe(success =>
        // this.alertService.success('Spots successfully added'), error => this.alertService.error('Spot not saved'));
      }
    });
  }

  openDeleteSponsorshipDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete sponsorship ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.deleteSponsorship(this.sponsorship.sponsorshipId);
        
      }
    });
  }

  async deleteSponsorship(sponsorshipNo)
  {
    this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/sales/delete_sponsorship.php?sponsorshipNo=${sponsorshipNo}`).pipe()
    .subscribe(
      (data:any) =>{

        this.sponsorshipService.getSponsorshipsFromRemote().subscribe(success => {
          return success;
        });

        this.alertService.success("Sponsorship successfully deleted !");

        localStorage.removeItem('selectedSponsorship');
        localStorage.removeItem('createdSponsorship');
        this.router.navigate(['/', 'home/sales']);

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  setEditedValues(editedSpot) {
    this.selection.selected.forEach(spot => {
      spot.breakScheduleDate = this.checkIfValue(editedSpot.breakScheduledDate) || spot.breakScheduleDate;
      spot.duration = this.checkIfValue(editedSpot.duration) || spot.duration;
      spot.element = this.checkIfValue(editedSpot.element) || spot.element;
      spot.discount = this.checkIfValue(editedSpot.discount) || spot.discount;
    });

    this.selection.clear();
  }

  checkIfValue(value) {
    if (value === '' || value === 0) {
      return null;
    } else {
      return value;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  returnToDashboard() {
    localStorage.removeItem('selectedSponsorship');
    localStorage.removeItem('createdSponsorship');
    this.router.navigate(['/', 'home']);
  }

  back(){
    this._location.back();
  }
}
