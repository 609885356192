import {Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {AlertService} from '../../services/alert/alert.service';
import { saveAs } from 'file-saver';
import {Router} from '@angular/router';
import {TargetAudienceService} from '../../services/target-audience/target-audience.service';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin} from 'rxjs'; 
import {BaseCppDialogComponent} from '../base-cpp-dialog/base-cpp-dialog.component';
import { HttpClient } from '@angular/common/http';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-day-targets',
  templateUrl: './day-targets.component.html',
  styleUrls: ['./day-targets.component.scss']
})
export class DayTargetsComponent implements OnInit {
  weeks: any[] = [];
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  baseRatings = [];
  displayedColumns: string[] = ['date', 'startTime', 'programme', 'topDeliveringDemo', 'rating', 'audienceBaseRate', 'weighting',
    'gross_target', 'net_target', 'genre'];
  rateSettingMonth: any;
  rateSettingModel: RateSettingModel;
  step = 0;
  data = [];
  selectedWeekIndex: number;
  selectedWeekData: any;
  tableSource: any[] = [];

  constructor(private rateSettingActionsService: RateSettingActionsService, private rateSettingService: RateSettingService,
              private alertService: AlertService, private router: Router, private targetAudienceService: TargetAudienceService,
              public dialog: MatDialog,private httpClient: HttpClient) {
  }

  ngOnInit() {
    const data = this.rateSettingActionsService.getRateSettingMon();
    if (data) {
      this.rateSettingMonth = data.month;
      this.rateSettingModel = data.rateSettingModel;
      this.daysOfWeek.forEach(day => {
        this.tableSource.push({
          day: day,
          data: []
        });
      });
      forkJoin(this.rateSettingService.getBaseRatingsForMonth(this.rateSettingMonth.month, this.rateSettingModel.channelId),
        this.rateSettingService.getMonthlyRatings(this.rateSettingModel.channelId, this.rateSettingMonth.month.toLowerCase(),
          this.rateSettingModel.year))
        .subscribe(results => {
          this.baseRatings = results[0];
          this.data = results[1];
          this.selectedWeekIndex = 0;
          for (let i = 0; i < this.data.length; i++) {
            this.weeks.push(i);
          }
          this.setWeekData(this.selectedWeekIndex);
        });
    }
  }

  getTimeSlotsForDay(day) {
    const daySlots = this.selectedWeekData.filter(weekData => {
      return weekData.day === day;
    });
    return daySlots.filter((data, index, self) =>
      index === self.findIndex((t) => (
        t.start_time === data.start_time
      ))
    );
  }

  setWeekData(weekIndex) {
    const objectName = 'week ' + (weekIndex + 1);
    this.selectedWeekData = this.data[weekIndex][objectName];
    this.clearTableData();
    this.createTableData();
  }

  createTableData() {
    this.tableSource.forEach(day => {
      const timeSlots = this.getTimeSlotsForDay(day.day);
      timeSlots.forEach(slot => {
        day.data.push({
          start_time: slot.start_time,
          programme: slot.programme,
          topDeliveringDemo: this.getTopDeliveringDemo(day.day, slot.start_time),
          projectedRate: 0,
          weighting: 0,
          genre: slot.genre,
          date: slot.date,
          gross_target: slot.gross_target,
          net_target: slot.net_target
        });
      });
    });
  }

  clearTableData() {
    this.tableSource = [];
    this.daysOfWeek.forEach(day => {
      this.tableSource.push({
        day: day,
        data: []
      });
    });
  }

  getProgrammeName(day, slot) {
    const rate = this.selectedWeekData.find(data => {
      return data.start_time === slot && data.day === day;
    });
    return rate && rate.programme || 'No programme data found';
  }

  getTopDeliveringDemo(day, slot) {
    const demos = this.selectedWeekData.filter(data => {
      return data.start_time === slot && data.day === day;
    });

    const topRating = Math.max.apply(Math, demos.map(function (demo) {
      return demo.rating;
    }));

    const topDemo = demos.find(demo => {
      return demo.rating === topRating;
    });

    return {
      demographic: topDemo && topDemo.demographic || 'Not set',
      rating: topDemo && topDemo.rating || 0,
      baseRating: this.getBaseRating(topDemo)
    };
  }

  getBaseRating(topDemo) {
    let baseRating = 0;
    if (topDemo) {
      baseRating = this.baseRatings.find(br => {

        if(topDemo.demographic == null || topDemo.demographic == ''){
          return br.demographic === topDemo.demographic;
        }
        else{
          return br.demographic.toLowerCase() === topDemo.demographic.toLowerCase();
        }
        
      });
    }
    return baseRating;
  } 

  changeDemographic(element, demographic, dayIndex) {
    const baseRating = this.baseRatings.find(br => {
      return br.demographic.toLowerCase() === demographic.toLowerCase();
    });
    const demos = this.selectedWeekData.filter(data => {
      return data.start_time === element.start_time && data.day === this.daysOfWeek[dayIndex];
    });

    const topDemo = demos.find(demo => {
      return demo.demographic === demographic;
    });

    this.tableSource[dayIndex].data.forEach(demog => {
      if (demog.start_time === element.start_time) {
        demog.topDeliveringDemo.rating = topDemo && topDemo.rating || 0;
        demog.topDeliveringDemo.baseRating = baseRating && parseFloat(baseRating.rate) || 0;
      }
    });
    // element.topDeliveringDemo.baseRatings = baseRating && parseFloat(baseRating.rate) || 0;
  }


  setStep(index: number) {
    this.step = index;
  }

  openBaseCppDialog() {
    const dialogRef = this.dialog.open(BaseCppDialogComponent, {
      width: '600px',
      data: {baseRatings: this.baseRatings, month: moment(this.rateSettingMonth.month, 'MMM').format('MMMM')}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.month !== 'January') {
        this.daysOfWeek.forEach((day, index) => {
          this.tableSource[index].data.forEach(demographic => {
            const baseRating = this.baseRatings.find(br => {
              return br.demographic.toLowerCase() === demographic.topDeliveringDemo.demographic.toLowerCase();
            });
            if (baseRating) {
              demographic.topDeliveringDemo.baseRating = baseRating.index_value * baseRating.Jan;
            }
          });
        });
      }
    });
  }

  mapResultsForDay(index) {
    return this.tableSource[index].data.map(demo => {
      return {
        channelId: this.rateSettingModel.channelId,
        start_time: demo.start_time,
        audienceRate: this.roundOffNumber(demo.topDeliveringDemo.rating * demo.topDeliveringDemo.baseRating) *
          ((demo.weighting / 100) + 1)
      };
    });
  }

  mapResult() {
    return this.daysOfWeek.map((day, index) => {
      return {
        day: day,
        month: this.rateSettingMonth.month,
        year: this.rateSettingModel.year,
        results: this.mapResultsForDay(index)
      };
    });
  }

  exportTargets() {
    this.rateSettingService.exportAudienceRates(this.mapResult()).subscribe(success => {
      this.success('audience',success);
    });
  }

  success(type,data) {
    const message = 'The ' + type + ' rates for ' + moment(this.rateSettingMonth.month, 'MMM').format('MMMM') + ' has been exported';
    this.alertService.success(message, true);

    var files = data.download_files.split(";");
    for(var i=0;i<(files.length-1);i++){ 
      this.export(files[i]).subscribe(data => saveAs(data, 'Audience Rates.xlsx')); 
    }

    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
  } 

  export(name) {
    return this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/sales/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }


  returnToTargets() {
    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
    this.router.navigate(['home/viewTargets']);
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  } 

}
