import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import * as currencies from '../../commom/currency.json';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { ClientClass } from 'src/app/classes/client';
import { SetupClass } from 'src/app/classes/setup';
import { ProductClass } from 'src/app/classes/product';
import { FlightingCode } from 'src/app/classes/flighting_code';
import { ClashClass } from 'src/app/classes/clash';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss'],
})
export class ViewProductComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  cur_obj: any = (currencies as any).default;
  setupClass:SetupClass = new SetupClass();
  product:ProductClass = new ProductClass(); 
  display_array: ClientClass[] = [];
  productId: number = 0;
  vatTypes = [{label: 'On top of price', value: 'On top of price'},{label: 'Included in price', value: 'Included in price'}];

  selectedFile: FileList  = null; 
  formData = new FormData();
  productFormGroup: FormGroup;

  flightingcodeFormGroup: FormGroup;
  flightingCode:FlightingCode = new FlightingCode(); 
  flightingcode_list: FlightingCode[] = [];
  durations: number[] = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90];
  clickedIndex: number = 0;

  //Table
  displayedColumns: string[] = ['select', 'flightingcode','duration','delete'];
  dataSource: any;
  selection = new SelectionModel<FlightingCode>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  clash_list: ClashClass[];  

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.productId = parseInt(JSON.parse(localStorage.getItem('productId')));
    this.onReadClients();
    this.onReadOneProduct();
    this.onReadFlightingCodes();
    this.onReadClashes();
  }
 
  ngOnInit() {
   
    this.flightingCode.prod_code = this.productId;
    this.product.quantity = 0;
    this.product.vat_rate = 0;
    this.product.discount = 0;
    this.product.unit_price = 0;
    this.product.turnVat = false;
    this.product.vat_type = {label: 'On top of price', value: 'On top of price'}; 

    this.flightingcodeFormGroup = this.formBuilder.group({
      durationCtrl: [this.flightingCode.duration, Validators.required],
      indury_codeCtrl: [this.flightingCode.indury_code, Validators.required],
    });

    this.productFormGroup = this.formBuilder.group({
      product_nameCtrl: [this.product.product_name, Validators.required],
      descriptionCtrl: [this.product.description],
      categoryCtrl: [this.product.category, Validators.required],
      clientNoCtrl: [this.product.clientNo, Validators.required],
    });
    
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  onTabChanged(event){

    let clickedIndex = event.index;
    if(clickedIndex == 0){
      this.clickedIndex = 0;
    }
    else{
      this.clickedIndex = 1;
    }

  }

  openDeleteDialog(codeId): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete flighting code ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.deleteFlightingCode(codeId);

        this.selection.clear();
        this.alertService.success('Flighting code successfully deleted')
      }
    });
  }

  async deleteFlightingCode(codeId)
  {

    this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/sales/delete_flighting_code.php?codeId=${codeId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadFlightingCodes();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadClashes(){ 

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/read_clashes.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.clash_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.clash_list[i] = {idNo: data[i].idNo,clash: data[i].clash,channel: data[i].channel};
        } 

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

async onReadFlightingCodes()
{

  this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/sales/get_existing_codes.php?prod_code=${this.productId}`).pipe()
  .subscribe(
    (data:any) =>{

      this.flightingcode_list = [];
    
      for(var i=0;i<data.length;i++)
      {
          this.flightingcode_list[i] = {ccpyId: data[i].ccpyId,duration: data[i].duration,indury_code: data[i].indury_code,prod_code: data[i].prod_code,actual_duration: data[i].actual_duration};
      } 

      this.dataSource = new MatTableDataSource<FlightingCode>(this.flightingcode_list);
      this.dataSource.paginator = this.paginator;
  
    },error => {
      this.alertService.error("Something went wrong, Try again");
    }
  )
}

  async onReadOneProduct()
  {

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/product/read_one_product.php`,
    {
        employee_no: 0,
        productId: this.productId,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

        for(var i=0;i<data.length;i++)
        {
            this.product.date_time = data[i].date_time;
            this.product.description = data[i].description;
            this.product.discount = data[i].discount;
            this.product.idNo = data[i].idNo;
            this.product.image = data[i].image;
            this.product.position = data[i].position;
            this.product.product_name = data[i].product_name;
            this.product.quantity = data[i].quantity;
            this.product.turnVat = Boolean(Number(data[i].turnVat));
            this.product.unit_price = data[i].unit_price;
            this.product.vat_rate = data[i].vat_rate;
            this.product.vat_type = data[i].vat_type;
            this.product.category = data[i].category;
            this.product.clientNo = data[i].clientNo;
        }

        for(var i=0;i<this.vatTypes.length;i++)
        {
            if(this.vatTypes[i].value == this.product.vat_type){
              this.product.vat_type = this.vatTypes[i];
            }
        }

      } ,error => {

        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadClients()
  {

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/client/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.display_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }


  async saveFlightingCode(){ 

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/sales/create_new_code.php`,
    {
      duration: this.flightingCode.duration,
      indury_code: this.flightingCode.indury_code,
      prod_code: this.flightingCode.prod_code,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.flightingcodeFormGroup.reset();
          this.alertService.success("Flighting code successfully saved");
          this.onReadFlightingCodes();
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async saveProduct(){

    this.formData.append('employee_no',<any>0);
    this.formData.append('product_name',<any>this.product.product_name);
    this.formData.append('description',<any>this.product.description);
    this.formData.append('quantity',<any>this.product.quantity);
    this.formData.append('unit_price',<any>this.product.unit_price);
    this.formData.append('discount',<any>this.product.discount);
    this.formData.append('turnVat',<any>this.product.turnVat);
    this.formData.append('vat_type',<any>this.product.vat_type);
    this.formData.append('vat_rate',<any>this.product.vat_rate);
    this.formData.append('clientNo',<any>this.product.clientNo);
    this.formData.append('productId',<any>this.productId);
    this.formData.append('category',<any>this.product.category);

    this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/sales/product/update_product.php',this.formData)
    .subscribe((message:any) => {
      
      if(message.message == 'success'){
        this.alertService.success('Product successfully saved !');
        this.router.navigate(['/home/products-list']);
      } 
      else{  
        this.alertService.error(message.message);
      } 
    },error => {
   
      this.alertService.error("Something went wrong, Try again");
    }); 
  }


  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}
