import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
 
//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { ClientClass } from 'src/app/classes/client';
import { DistributorClass } from 'src/app/classes/distributor';

@Component({
  selector: 'app-distributor-list',
  templateUrl: './distributor-list.component.html',
  styleUrls: ['./distributor-list.component.scss'],
})
export class DistributorListComponent implements OnInit {

  campaign: any;
  display_array: DistributorClass[] = [];
  baseUrl = environment.serverUrl;
  displayedColumns: string[] = ['select','distributor', 'contact_person', 'phone', 'email','edit'];
  dataSource: any;
  selection = new SelectionModel<DistributorClass>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient, private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,) {

    this.onReadDistributors();
  }

  ngOnInit() {
   
    
  }

  /*ionViewWillEnter(){

    this.onReadUsers();
  }*/

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected distributor ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.selection.selected.forEach(distributor => {          
          this.deleteDistributor(distributor.idNo);
        });
        
        this.selection.clear(); 
        this.alertService.success('Distributor successfully deleted')
      }
    });
  }

  async deleteDistributor(distributorID)
  {
    this.httpClient.get(`https://capetowntv.mmsafrica.co.za/schedule/delete_distributor.php?distributorID=${distributorID}&employee_no=0`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadDistributors();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  async onReadDistributors()
  {

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/read_distributors.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        { 
            this.display_array[i] = {idNo: data[i].idNo,contact_person: data[i].contact_person,distributor: data[i].distributor,email: data[i].email,phone: data[i].phone};
        }

        this.dataSource = new MatTableDataSource<DistributorClass>(this.display_array);
        this.dataSource.paginator = this.paginator;
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  editDistributor(distributorID){

    localStorage.setItem('distributorID', JSON.stringify(distributorID));
    this.router.navigate(['/home/view-distributor']);
  }
 
  addNewDistributor(){
    this.router.navigate(['/home/new-distributor']);
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }
}