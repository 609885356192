import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {AlertService} from '../../services/alert/alert.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forgort-password',
  templateUrl: './forgort-password.component.html',
  styleUrls: ['./forgort-password.component.scss'],
}) 

export class ForgortPasswordComponent implements OnInit {

  signInForm: FormGroup; 

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,private httpClient: HttpClient) { }

  ngOnInit() {

    this.signInForm = this.fb.group({
      'email': [null, [Validators.required, <any>Validators.pattern(
        '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
      )]]
    });
  }

  get f() {
    return this.signInForm.controls;
  }

  async resetPassword()
  {

    const response = this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/admin/forgot_password.php',
    {
      email: this.f.email.value,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
        
        if(data.message == "success")
        {
          this.alertService.success("Email sent with password reset link");
        }
        else
        { 
          this.alertService.error(data.message);
        }
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
  }
}