import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {Location} from '@angular/common';
 
//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY', 
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-copy-schedule',
  templateUrl: './copy-schedule.component.html',
  styleUrls: ['./copy-schedule.component.scss'],
})
export class CopyScheduleComponent implements OnInit {

  employee_no: number = 0;
  scheduleFormGroup: FormGroup;

  channels: SareClass[] = [];
  selected_channels: number = 0;

  prog_array: ProgrammeClass[] = [];
  selected_programmes: number[] = [];

  start_date: String;
  end_date: String;
  start_time: String;
  end_time: String;

  start_date_to: String;
  end_date_to: String;
  new_episodes: any = 0;
  skipped_episodes: any = 0;
  copy_episodes: any = 0;

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));

    this.onChannels();
  }

  ngOnInit() {

    this.scheduleFormGroup = this.formBuilder.group({
      channelsCtrl: [this.selected_channels,Validators.required],
      programmesCtrl: [this.selected_programmes,Validators.required],
      start_dateCtrl: [this.start_date,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      start_date_toCtrl: [this.start_date_to,Validators.required],
      end_date_toCtrl: [this.end_date_to,Validators.required],
    });

  }

  validatePeriod()
  {
    var dif_daye = this.daysDiff(this.start_date,this.end_date) + 1;

    if(dif_daye > 7)
    {
      this.alertService.error('You can copy episodes from one week (up-to 7 days) to the rest of months');
      this.end_date = '';
    }
  }


  daysDiff(d1, d2) {
    let hours = this.hoursDiff(d1, d2);
    let daysDiff = Math.floor( hours / 24 );
    return daysDiff;
  }

  hoursDiff(d1, d2) {
    let minutes = this.minutesDiff(d1, d2);
    let hoursDiff = Math.floor( minutes / 60 );
    return hoursDiff;
  }
  
  minutesDiff(d1, d2) {
    let seconds = this.secondsDiff(d1, d2);
    let minutesDiff = Math.floor( seconds / 60 );
    return minutesDiff;
  }
  
  secondsDiff(d1, d2) {
    let millisecondDiff = d2 - d1;
    let secDiff = Math.floor( ( d2 - d1) / 1000 );
    return secDiff;
  }

  async copySchedule(){ 

    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");
    var strstart_date_to = moment(this.start_date_to.toString()).format("YYYY/MM/DD");
    var strend_date_to = moment(this.end_date_to.toString()).format("YYYY/MM/DD");

    var strstart_time = moment(this.start_time.toString()).format("HH:mm:ss");
    var strend_time = moment(this.end_time.toString()).format("HH:mm:ss");
    
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/schedule/copy_schedule.php`,
    {
      programmes: this.selected_programmes,
      start_date: strstart_date,
      end_date: strend_date,
      start_date_to: strstart_date_to,
      end_date_to: strend_date_to,
      start_time: strstart_time,
      end_time: strend_time,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Schedule successfully copied !');
          this.new_episodes = data.episodes;
          this.skipped_episodes = data.skipped;
          this.copy_episodes = data.copy_episodes;
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async onReadProgrammes(){ 
      
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
    {
      channes: [this.selected_channels],
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.prog_array = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.prog_array[i] = {genre: data[i].genre,image: data[i].image,language: data[i].language,length: data[i].length,prod_house: data[i].prod_house,producer: data[i].producer,prog_name: data[i].prog_name,prog_no: data[i].prog_no,bdcr_no: data[i].bdcr_no,type: data[i].type,category: data[i].category,rcrd_no: data[i].rcrd_no,repeat_name: data[i].repeat_name,sare_no: data[i].sare_no,subgenre: data[i].subgenre,season_data: data[i].season_data,genre_sentech: data[i].genre_sentech,subgenre_sentech: data[i].subgenre_sentech,
              contract_no: data[i].contract_no,
              contract_type: data[i].contract_type,
              distributors: data[i].distributors}; 
        }

      } 
    ) 
  }

  async onChannels(){ 

    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channels,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.channels = [];

        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  back(){
    this._location.back();
  }

}
