import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Router} from '@angular/router';
import {User} from '../../models/user';
import {LoadingScreenService} from '../../services/loading-screen/loading-screen.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {saveAs} from "file-saver";
import {SareClass} from "../../classes/sare"; 
import {HttpClient} from "@angular/common/http";
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {AlertService} from "../../services/alert/alert.service";
import {MatAccordion} from '@angular/material/expansion';
import * as $ from "jquery";


const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    currentUser: User;
    opened: boolean;
    loading = false;
    loadingSubscription: Subscription;
    employee_no: any;
    channels: SareClass[] = [];
    formData = new FormData();
    search_text: String = '';
    role:string = '';


    //Exports
    spotsFormGroup: FormGroup;
    breaksFormGroup: FormGroup;
    programmeFormGroup: FormGroup;
    scheduleFormGroup: FormGroup;
    episodicFormGroup: FormGroup;
    playlistFormGroup: FormGroup;
    selected_channel:string = "1";

    chk_email = true;
    chk_export = true;
    chk_download = true;
    chk_attach = false;
    chk_highlight = false;
    start_date: String = moment().format("YYYY-MM-DD");
    end_date: String = moment().format("YYYY-MM-DD");
    start_time: String = "00:00";
    end_time: String = "23:59";
    export_format: String = 'excel';
    chk_sentech: boolean = false;
    chk_multichoice: boolean = false;

    //Imports
    spotsFormGroup_import: FormGroup;
    materialFormGroup_import: FormGroup;
    asRunFormGroup_import: FormGroup;
    scheduleFormGroup_import: FormGroup;
    programmeFormGroup_import: FormGroup;

    isLinear: true;
    selectedFile: FileList  = null;
    asRunFormGroup: FormGroup;
    materialFormGroup: FormGroup;

    chk_save = false;
    chk_vertical = false;
    chk_horizontal = false;
    chk_importfromFTP = false;
    import_format: String = 'fullformat'; 
    blnOveride_schedule: boolean = false;
    prog_skipped: number = 0;
    prog_saved: number = 0;
    sea_skipped: number = 0;
    sea_saved: number = 0;
    epi_skipped: number = 0;
    epi_saved: number = 0;

    @ViewChild(MatAccordion) accordion: MatAccordion;

    constructor(private authenticationService: AuthenticationService,
                private router: Router, private loadingScreenService: LoadingScreenService,
                private formBuilder: FormBuilder, private httpClient: HttpClient,
                private alertService: AlertService) {

        this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
        this.role = JSON.parse(localStorage.getItem('role'));
        this.onChannels();
    }

    ngOnInit() {
        this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value) => {
            this.loading = value;
        });
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.opened = false;

        //Imports
        this.programmeFormGroup_import = this.formBuilder.group({
            selected_channelCtrl: [this.selected_channel,Validators.required],
          });
      
          this.scheduleFormGroup_import = this.formBuilder.group({
            selected_channelCtrl: [this.selected_channel,Validators.required],
            import_formatCtrl: [this.import_format,Validators.required],
            blnOveride_scheduleCtrl: [this.blnOveride_schedule],
          });
      
          this.asRunFormGroup_import = this.formBuilder.group({
            chk_downloadCtrl: [this.chk_download,Validators.required],
            chk_emailCtrl: [this.chk_email,Validators.required],
            chk_exportCtrl: [this.chk_export,Validators.required],
            selected_channelCtrl: [this.selected_channel,Validators.required],
            start_dateCtrl: [this.start_date,Validators.required],
            end_dateCtrl: [this.end_date,Validators.required],
            start_timeCtrl: [this.start_time,Validators.required],
            end_timeCtrl: [this.end_time,Validators.required],
            chk_importfromFTPCtrl: [this.chk_importfromFTP,Validators.required],
          });
      
          this.materialFormGroup_import = this.formBuilder.group({
            chk_downloadCtrl: [this.chk_download],
            selected_channelCtrl: [this.selected_channel,Validators.required],
            chk_importfromFTPCtrl: [this.chk_importfromFTP],
          });
          
          this.spotsFormGroup_import = this.formBuilder.group({
            chk_saveCtrl: [this.chk_save,Validators.required],
            chk_verticalCtrl: [this.chk_vertical,Validators.required],
            //chk_horizontalCtrl: [this.chk_horizontal,Validators.required],
            chk_emailCtrl: [this.chk_email,Validators.required],
            chk_exportCtrl: [this.chk_export,Validators.required],
            selected_channelCtrl: [this.selected_channel,Validators.required],
            start_dateCtrl: [this.start_date,Validators.required],
            //end_dateCtrl: [this.end_date,Validators.required],
            start_timeCtrl: [this.start_time,Validators.required],
            end_timeCtrl: [this.end_time,Validators.required],
            chk_importfromFTPCtrl: [this.chk_importfromFTP,Validators.required],
          });


        //Exports
        this.breaksFormGroup = this.formBuilder.group({
            chk_downloadCtrl: [this.chk_download,Validators.required],
            chk_emailCtrl: [this.chk_email,Validators.required],
            chk_exportCtrl: [this.chk_export,Validators.required],
            chk_attachCtrl: [this.chk_attach,Validators.required],
            selected_channelCtrl: [this.selected_channel,Validators.required],
            start_dateCtrl: [this.start_date,Validators.required],
            end_dateCtrl: [this.end_date,Validators.required],
            start_timeCtrl: [this.start_time,Validators.required],
            end_timeCtrl: [this.end_time,Validators.required],
            export_formatCtrl: [this.export_format],
          });
      
          this.programmeFormGroup = this.formBuilder.group({
            chk_downloadCtrl: [this.chk_download,Validators.required],
            chk_emailCtrl: [this.chk_email,Validators.required],
            chk_exportCtrl: [this.chk_export,Validators.required],
            chk_attachCtrl: [this.chk_attach,Validators.required],
            selected_channelCtrl: [this.selected_channel,Validators.required],
            search_textCtrl: [this.search_text],
          });
          
          this.scheduleFormGroup = this.formBuilder.group({
            chk_downloadCtrl: [this.chk_download,Validators.required],
            chk_emailCtrl: [this.chk_email,Validators.required],
            chk_exportCtrl: [this.chk_export,Validators.required],
            chk_attachCtrl: [this.chk_attach,Validators.required],
            selected_channelCtrl: [this.selected_channel,Validators.required],
            chk_highlightCtrl: [this.chk_highlight,Validators.required],
            export_formatCtrl: [this.export_format,Validators.required],
            start_dateCtrl: [this.start_date,Validators.required],
            end_dateCtrl: [this.end_date,Validators.required],
            start_timeCtrl: [this.start_time,Validators.required],
            end_timeCtrl: [this.end_time,Validators.required],
            chk_sentechCtrl: [this.chk_sentech],
            chk_multichoiceCtrl: [this.chk_multichoice],
          });
    }

    ngAfterViewInit(){
    
        //File import
        $("form").on("change", ".file-upload-field", function(){ 
          $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replace(/.*(\/|\\)/, '') );
        });
    
      }

      onFileSelected(event){
    
        this.selectedFile  = null;
        this.selectedFile = <FileList>event.target.files;
      }

    redirect(path) {
        this.router.navigate(['home/' + path]);
    }

    returnToDashboard() {
        this.router.navigate(['/home']);
    }

    signOut() {
        this.authenticationService.logout();
        this.router.navigate(['signin']);
    }

    async onChannels() {
        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/get_channels.php`,
            {
                channes: this.selected_channel,
                date: moment().format("YYYY/MM/DD"),
                employee_no: this.employee_no,

            }, {responseType: 'json'}).pipe()
            .subscribe(
                (data: any) => {

                    for (let i = 0; i < data.length; i++) {
                        this.channels[i] = {
                            bdcr_no: data[i].bdcr_no,
                            genre: data[i].genre,
                            platform: data[i].platform,
                            sare_name: data[i].sare_name,
                            sare_no: data[i].sare_no
                        };
                    }

                }
            )
    }


    async exportProgrammes() {

        this.formData.append('employee_no', <any>this.employee_no);
        this.formData.append('selected_channel', <any>this.selected_channel);
        this.formData.append('chk_download', <any>true);
        this.formData.append('chk_email', <any>false);
        this.formData.append('chk_export', <any>false);
        this.formData.append('chk_attach', <any>false);
        this.formData.append('search_text', <any>this.search_text);

        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/export_programmes.php', this.formData, {responseType: 'json'})
            .subscribe((data: any) => {

                if (data.message == 'success') {
                    this.alertService.success('Programmes successfully exported');

                    const files = data.download_files.split(";");

                    for (let i = 0; i < (files.length - 1); i++) {
                        this.export(files[i]).subscribe(data => saveAs(data, 'Programme Export.xlsx'));
                    }

                } else {
                    this.alertService.error(data.message);
                }

            });
    }

    async exportEpisodic() {

        this.formData.append('employee_no', <any>this.employee_no);
        this.formData.append('selected_channel', <any>this.selected_channel);
        this.formData.append('chk_download', <any>true);
        this.formData.append('chk_email', <any>false);
        this.formData.append('chk_export', <any>false);
        this.formData.append('chk_attach', <any>false);
        this.formData.append('search_text', <any>this.search_text);

        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/export_episodic.php', this.formData, {responseType: 'json'})
            .subscribe((data: any) => {

                if (data.message == 'success') {
                    this.alertService.success('Episodic successfully exported');

                    const files = data.download_files.split(";");

                    for (let i = 0; i < (files.length - 1); i++) {
                        this.export(files[i]).subscribe(data => saveAs(data, 'Episodic Export.xlsx'));
                    }

                } else {
                    this.alertService.error(data.message);
                }

            });
    }

    exportSchedule(){

        if(this.export_format == 'excel'){
            this.exportScheduleExcel();
        }
        else{
          
            if(this.chk_multichoice == true || this.chk_sentech == true){
              if(this.chk_multichoice == true){
                this.exportScheduleXML('export_schedule_xml.php');
              }
      
              if(this.chk_sentech == true){
                this.exportScheduleXML('export_sentech_schedule_xml.php');
              }
            } 
            else{
              this.alertService.error("Select at least one media type !");
            }
        }
      }
    
      async exportScheduleXML(apiFile){
    
        var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
        var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");
    
        var strstart_time = this.start_time.toString()+":00";
        var strend_time = this.end_time.toString()+":00";
     
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('chk_download',<any>this.chk_download);
        this.formData.append('chk_email',<any>this.chk_email);
        this.formData.append('chk_export',<any>this.chk_export);
        this.formData.append('chk_attach',<any>this.chk_attach);
        this.formData.append('chk_highlight',<any>this.chk_highlight);
        this.formData.append('export_format',<any>this.export_format);
        this.formData.append('strstart_date',<any>strstart_date);
        this.formData.append('strend_date',<any>strend_date);
        this.formData.append('strstart_time',<any>strstart_time);
        this.formData.append('strend_time',<any>strend_time);
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/'+apiFile,this.formData,{ responseType: 'json'})
        .subscribe((data:any) => {
           
    
          if(data.message == 'success')
          {
            this.alertService.success('Programme schedule successfully exported');
    
            var files = data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              this.export(files[i]).subscribe(data => saveAs(data, 'programme schedule.xml')); 
            }
     
          }
          else
          {
            this.alertService.error(data.message);
          }
    
        });
      }
    
      async exportScheduleExcel(){
    
        var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
        var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");
    
        var strstart_time = this.start_time.toString()+":00";
        var strend_time = this.end_time.toString()+":00";
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('chk_download',<any>this.chk_download);
        this.formData.append('chk_email',<any>this.chk_email);
        this.formData.append('chk_export',<any>this.chk_export);
        this.formData.append('chk_attach',<any>this.chk_attach);
        this.formData.append('chk_highlight',<any>this.chk_highlight);
        this.formData.append('export_format',<any>this.export_format);
        this.formData.append('strstart_date',<any>strstart_date);
        this.formData.append('strend_date',<any>strend_date);
        this.formData.append('strstart_time',<any>strstart_time);
        this.formData.append('strend_time',<any>strend_time);
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/export_schedule.php',this.formData,{ responseType: 'json'})
        .subscribe((data:any) => {
          
    
          if(data.message == 'success')
          {
            this.alertService.success('Programme schedule successfully exported');
    
            var files = data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              this.export(files[i]).subscribe(data => saveAs(data, 'Schedule Export.xlsx')); 
            }
     
          }
          else
          {
            this.alertService.error(data.message);
          }
    
        });
      }

    async exportBreaks() {

        const strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
        const strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

        const strstart_time = this.start_time.toString() + ":00";
        const strend_time = this.end_time.toString() + ":00";

        this.formData.append('employee_no', <any>this.employee_no);
        this.formData.append('selected_channel', <any>this.selected_channel);
        this.formData.append('chk_download', <any>true);
        this.formData.append('chk_email', <any>false);
        this.formData.append('chk_export', <any>false);
        this.formData.append('chk_attach', <any>false);
        this.formData.append('strstart_date', <any>strstart_date);
        this.formData.append('strend_date', <any>strend_date);
        this.formData.append('strstart_time', <any>strstart_time);
        this.formData.append('strend_time', <any>strend_time);

        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/export_breaks.php', this.formData, {responseType: 'json'})
            .subscribe((data: any) => {

                if (data.message == 'success') {
                    this.alertService.success('Schedule breaks successfully exported');

                    const files = data.download_files.split(";");

                    for (let i = 0; i < (files.length - 1); i++) {
                        this.export(files[i]).subscribe(data => saveAs(data, 'Break Export.xlsx'));
                    }

                } else {
                    this.alertService.error(data.message);
                }

            });
    }

    async exportSpots(){

        var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
        var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");
    
        var strstart_time = this.start_time.toString()+":00";
        var strend_time = this.end_time.toString()+":00";
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('chk_download',<any>this.chk_download);
        this.formData.append('chk_email',<any>this.chk_email);
        this.formData.append('chk_export',<any>this.chk_export);
        this.formData.append('chk_attach',<any>this.chk_attach);
        this.formData.append('strstart_date',<any>strstart_date);
        this.formData.append('strend_date',<any>strend_date);
        this.formData.append('strstart_time',<any>strstart_time);
        this.formData.append('strend_time',<any>strend_time);
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/export_spots.php',this.formData,{ responseType: 'json'})
        .subscribe((data:any) => {
    
    
          if(data.message == 'success')
          {
            this.alertService.success('Schedule spots successfully exported');
    
            var files = data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              this.export(files[i]).subscribe(data => saveAs(data, 'Spots Export.xlsx')); 
            }
     
          }
          else
          {
            this.alertService.error(data.message);
          }
    
        });
      }

      exportPlaylist(){

        if(this.export_format == 'excel'){
          this.exportPlaylistExcel();
      }
      else{
          this.exportPlaylistExcel();
      }
      }
    
      async exportPlaylistExcel(){
    
        var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
        var strstart_time = this.start_time.toString()+":00";
        var strend_time = this.end_time.toString()+":00";
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('chk_download',<any>this.chk_download);
        this.formData.append('chk_email',<any>this.chk_email);
        this.formData.append('chk_export',<any>this.chk_export);
        this.formData.append('chk_attach',<any>this.chk_attach);
        this.formData.append('strstart_date',<any>strstart_date);
        this.formData.append('strend_date',<any>strstart_date);
        this.formData.append('strstart_time',<any>strstart_time);
        this.formData.append('strend_time',<any>strend_time);
        this.formData.append('export_format',<any>this.export_format);
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/transmission/export_playlist.php',this.formData,{ responseType: 'json'})
        .subscribe((data:any) => {
          
          if(data.message == 'success')
          {
            this.alertService.success('Playlist successfully exported');
    
            var files = data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              var fileName = files[i].split("/")[1];
              this.export_plst(files[i]).subscribe(data => saveAs(data, fileName)); 
            }
     
          }
          else
          {
            this.alertService.error(data.message);
          }
    
        });
      }

      async importSpots(){   
    
        if(this.selectedFile != null)
        {
          for (var x = 0; x < this.selectedFile.length; x++) {
            this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
          }
        }
        
        var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
        //var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");
    
        var strstart_time = this.start_time.toString()+":00";
        var strend_time = this.end_time.toString()+":00";
    
        
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('chk_save',<any>this.chk_save);
        this.formData.append('chk_vertical',<any>this.chk_vertical);
        //this.formData.append('chk_horizontal',<any>this.chk_horizontal);
        this.formData.append('chk_email',<any>this.chk_email);
        this.formData.append('chk_export',<any>this.chk_export);
        this.formData.append('start_date',<any>strstart_date);
        //this.formData.append('end_date',<any>strend_date);
        this.formData.append('start_time',<any>strstart_time);
        this.formData.append('end_time',<any>strend_time);
        this.formData.append('chk_importfromFTP',<any>this.chk_importfromFTP);
        this.formData.append('ftpHost',<any>'');
        this.formData.append('ftpUsername',<any>'');
        this.formData.append('ftpPassword',<any>'');

    
        //https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/import_spots.php
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/ad_tracking_schedule.php',this.formData,{ responseType: 'json'})
        .subscribe((data:any) => {
    
          if(data.message == 'success')
          {
            this.alertService.success('Spots successfully saved');
        
            var files = data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              this.export(files[i]).subscribe(data => saveAs(data, 'Ad Tracking Schedule.xlsx')); 
            }
     
          }
          else
          {
            this.alertService.error(data.message);
          }
    
          this.formData = new FormData();
    
        });
      }


      async importAsRun(){
    
        if(this.selectedFile != null)
        {
          for (var x = 0; x < this.selectedFile.length; x++) {
            this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
          }
        }
        
        var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
        var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");
    
        var strstart_time = this.start_time.toString()+":00";
        var strend_time = this.end_time.toString()+":00";
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('chk_download',<any>this.chk_download);
        this.formData.append('chk_email',<any>this.chk_email);
        this.formData.append('chk_export',<any>this.chk_export);
        this.formData.append('start_date',<any>strstart_date);
        this.formData.append('end_date',<any>strend_date);
        this.formData.append('start_time',<any>strstart_time);
        this.formData.append('end_time',<any>strend_time);
        this.formData.append('chk_importfromFTP',<any>this.chk_importfromFTP);
        this.formData.append('ftpHost',<any>'');
        this.formData.append('ftpUsername',<any>'');
        this.formData.append('ftpPassword',<any>'');
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/import_asrun.php',this.formData,{ responseType: 'json'})
        .subscribe((res_data:any) => {
          
          if(res_data.message == 'success')
          {
            this.alertService.success('As-Run successfully saved');
            var files = res_data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              var strName = files[i];
              this.export(strName).subscribe(data2 => saveAs(data2, 'As-Run Export.xlsx'));
            }
     
          }
          else
          {
            this.alertService.error(res_data.message);
          }
    
          this.formData = new FormData();
    
        });
      }

      async importMaterial(){
    
        if(this.selectedFile != null)
        {
          for (var x = 0; x < this.selectedFile.length; x++) {
            this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
          }
        }
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('chk_download',<any>this.chk_download);
        this.formData.append('chk_importfromFTP',<any>this.chk_importfromFTP);
        this.formData.append('ftpHost',<any>'');
        this.formData.append('ftpUsername',<any>'');
        this.formData.append('ftpPassword',<any>'');
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/material/import_material_dvplay.php',this.formData,{ responseType: 'json'})
        .subscribe((res_data:any) => {
          
          if(res_data.message == 'success')
          {
            this.alertService.success('Material successfully saved');
            var files = res_data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              var strName = files[i];
              this.export(strName).subscribe(data2 => saveAs(data2, 'Material Export.xlsx'));
            }
     
          }
          else
          {
            this.alertService.error(res_data.message);
          }
    
          this.formData = new FormData();
    
        });
      }

      async importProgrammes(){
     
        if(this.selectedFile != null) 
        {
          for (var x = 0; x < this.selectedFile.length; x++) {
            this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
          }
        }
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/import_programme.php',this.formData,{ responseType: 'json'})
        .subscribe((res_data:any) => {
          
    
          if(res_data.message == 'success')
          {
            this.alertService.success('Programmes successfully imported !');
    
            this.prog_skipped = res_data.prog_skipped;
            this.prog_saved = res_data.prog_saved;
            this.sea_skipped = res_data.sea_skipped;
            this.sea_saved = res_data.sea_saved;
            this.epi_skipped = res_data.epi_skipped;
            this.epi_saved = res_data.epi_saved;
    
            var files = res_data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              this.export(files[i]).subscribe(data => saveAs(data, 'Skipped Report.xlsx')); 
            }
          }
          else
          {
            this.alertService.error(res_data.message);
          }
    
          this.formData = new FormData();
        });
      }

      async importSchedule(){
  
    
        if(this.selectedFile != null)
        {
          for (var x = 0; x < this.selectedFile.length; x++) {
            this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
          }
        }
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
        this.formData.append('import_format',<any>this.import_format);
        this.formData.append('blnOveride',<any>this.blnOveride_schedule);
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/import_schedule.php',this.formData,{ responseType: 'json'})
        .subscribe((res_data:any) => {
          
          if(res_data.message == 'success')
          {
            this.alertService.success('Schedule successfully imported !');
            
            this.prog_skipped = res_data.prog_skipped;
            this.prog_saved = res_data.prog_saved;
            this.sea_skipped = res_data.sea_skipped;
            this.sea_saved = res_data.sea_saved;
            this.epi_skipped = res_data.epi_skipped;
            this.epi_saved = res_data.epi_saved;
    
            var files = res_data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              this.export(files[i]).subscribe(data => saveAs(data, 'Skipped Report.xlsx')); 
            }
          }
          else
          {
            this.alertService.error(res_data.message);
          }
    
          this.formData = new FormData();
    
        });
      }

      async importEpisodic(){
    
        if(this.selectedFile != null)
        {
          for (var x = 0; x < this.selectedFile.length; x++) {
            this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
          }
        }
    
        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channel);
    
        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/import_episodic.php',this.formData,{ responseType: 'json'})
        .subscribe((res_data:any) => {
          
    
          if(res_data.message == 'success')
          {
            this.alertService.success('Schedule successfully imported !');
            
            this.prog_skipped = res_data.prog_skipped;
            this.prog_saved = res_data.prog_saved;
            this.sea_skipped = res_data.sea_skipped;
            this.sea_saved = res_data.sea_saved; 
            this.epi_skipped = res_data.epi_skipped;
            this.epi_saved = res_data.epi_saved;
    
            var files = res_data.download_files.split(";");
    
            for(var i=0;i<(files.length-1);i++)
            {
              this.export(files[i]).subscribe(data => saveAs(data, 'Skipped Report.xlsx')); 
            }
          }
          else
          {
            this.alertService.error(res_data.message);
          }
    
          this.formData = new FormData();
    
        });
      }

      onTabChanged(index){

        let clickedIndex = index;
        this.selectedFile  = null;
        this.chk_save = false;
        this.chk_vertical = false;
        this.chk_horizontal = false;
        this.chk_email = false;
        this.chk_export = false;
        this.chk_download = false;
        this.blnOveride_schedule = false;
        this.prog_skipped = 0;
        this.prog_saved = 0;
        this.sea_skipped = 0;
        this.sea_saved = 0;
        this.epi_skipped = 0;
        this.epi_saved = 0;
    
        if(clickedIndex == 0){ 
    
          //File import
          $("form").on("change", ".file-upload-field", function(){ 
            $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replace(/.*(\/|\\)/, '') );
          });
    
        }
        else
        if(clickedIndex == 1){ 
    
          //File import
          $("form").on("change", ".file-upload-field1", function(){ 
            $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replace(/.*(\/|\\)/, '') );
          });
        }
        else
        if(clickedIndex == 2){
    
          //File import
          $("form").on("change", ".file-upload-field4", function(){ 
            $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replace(/.*(\/|\\)/, '') );
          });
    
        }
        else
        if(clickedIndex == 3){ 
    
          //File import
          $("form").on("change", ".file-upload-field6", function(){ 
            $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replace(/.*(\/|\\)/, '') );
          });
        }
        else
        if(clickedIndex == 4){ 
    
          //File import
          $("form").on("change", ".file-upload-field3", function(){ 
            $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replace(/.*(\/|\\)/, '') );
          });
        }
        else
        if(clickedIndex == 5){ 
    
          //File import
          $("form").on("change", ".file-upload-field5", function(){ 
            $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replace(/.*(\/|\\)/, '') );
          });
        }
      }

    export(name) {
        return this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/schedule/schedule_files/download_file.php?file_name=${name}`,
            {responseType: 'blob'});
    }

    export_plst(name) {
      return this.httpClient.get(`https://capetowntv.mmsafrica.co.za/api/schedule/transmission/download_file.php?file_name=${name}`, 
        {responseType: 'blob'});
    }

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
    }
}
