import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl, NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem, TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {ContractClass} from '../../classes/acquisition';
import {LanguageClass} from '../../classes/language';
import {PGClass} from '../../classes/parentalguidance';
import {ContractListClass} from '../../classes/contractlist';
import {GenreClass} from '../../classes/genre';
import {SeasonClass} from '../../classes/season';
import {UserClass} from '../../classes/users';
import {SubGenreClass} from '../../classes/subgenre';
import {CategoryClass} from '../../classes/categories'; 
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import * as currencies from '../../commom/currency.json';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';

//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NavController} from '@ionic/angular';

//Toasta
import {Router, ActivatedRoute} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { RightsManagement } from 'src/app/classes/rightsManagement';
import { SetupClass } from 'src/app/classes/setup';
import { RegionClass } from 'src/app/classes/regions';
import { DistributorClass } from 'src/app/classes/distributor';
import { Demographics } from 'src/app/classes/demographics.class';

const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-view-programme',
    templateUrl: './view-programme.component.html',
    styleUrls: ['./view-programme.component.scss'],
})
export class ViewProgrammeComponent implements OnInit {

    employee_no: number = 0;
    isLinear = false;
    selectedFile: FileList = null;
    formData = new FormData();

    cur_obj: any = (currencies as any).default;
    setupClass:SetupClass = new SetupClass();
    currency: string[] = [];

    programmeFormGroup: FormGroup;
    seasonFormGroup: FormGroup;
    rightsFormGroup: FormGroup;

    selected_channel: number = 0;
    parentalG: String = '';
    channels: SareClass[] = [];
    genres: GenreClass[] = [];
    sentech_genres: GenreClass[] = [];
    languages: LanguageClass[] = [];
    contractTypes: ContractListClass[] = [];
    pgs: PGClass[] = [];
    users: UserClass[] = [];
    categories: CategoryClass[] = [];
    subgenries: SubGenreClass[] = [];
    sentech_subgenries: SubGenreClass[] = [];
    selected_subgenries: SubGenreClass[] = [];
    selected_sentech_subgenries: SubGenreClass[] = [];

    programme: ProgrammeClass = new ProgrammeClass();
    contract: ContractClass = new ContractClass();
    season: SeasonClass = new SeasonClass();

    //Rights Management
    regions: RegionClass[] = [];
    type_of_rights: CategoryClass[] = [];
    media_types: CategoryClass[] = [];
    distributors_array: DistributorClass[] = [];
    demographics_array: Demographics[] = [];
    rightsManagement: RightsManagement = new RightsManagement();
    distributors = 0;
    internalCategory = '';
    targetDemographic = 0;
    scheduleTime = '';
    currency_code = 'ZAR';
    licence_fee_Sidebar: boolean = false; 

    defaultTitle: String ='';
    genericSynopsis: String = '';
    chkLN = false;
    chkN = false;
    chkS = false;
    chkV = false;
    chkB = false;
    chkD = false;
    chkP = false;

    display_array: SeasonClass[] = [];
    displayedColumns: string[] = ['select', 'season', 'episodes','segments','year', 'producer', 'director','hashtags', 'actors', 'View'];
    dataSource = new MatTableDataSource(this.display_array);
    selection = new SelectionModel<SeasonClass>(true, []);

    constructor(private alertService: AlertService, route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private httpClient: HttpClient, private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog, private _location: Location) {
        this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
        this.programme.prog_no = parseInt(JSON.parse(localStorage.getItem('prog_no')));

        this.onChannels();
        this.onReadProgrammes();
        this.onReadRightsManagement();
    }

    ngOnInit() {

        this.programmeFormGroup = this.formBuilder.group({
            channelsCtrl: [this.selected_channel, Validators.required],
            contractNoCtrl: [this.contract.contract_no],
            progNameCtrl: [this.programme.prog_name, Validators.required],
            prdHouseCtrl: [this.programme.prod_house],
            prdPartnerCtrl: [this.contract.prod_partner],
            progLengthCtrl: [this.programme.length, Validators.required],
            genreCtrl: [this.programme.genre],
            distributorsCtrl: [this.distributors],
            contTypeCtrl: [this.contract.contract_type],
            languageCtrl: [this.programme.language],
            subgenryCtrl: [this.programme.subgenre],
            categoryCtrl: [this.programme.category],
            genre_sentechCtrl: [this.programme.genre_sentech],
            subgenre_sentechCtrl: [this.programme.subgenre_sentech],
        });

        this.seasonFormGroup = this.formBuilder.group({
            seasonNoCtrl: [this.season.seas_no, Validators.required],
            numberOfEpisodesCtrl: [this.season.episodes, Validators.required],
            segmentsCtrl: [this.season.segments, Validators.required],
            defaultTitleCtrl: [this.defaultTitle],
            genericSynopsisCtrl: [this.genericSynopsis],
            chkLNCtrl: [this.chkLN],
            chkNCtrl: [this.chkN],
            chkSCtrl: [this.chkS],
            chkVCtrl: [this.chkV],
            chkBCtrl: [this.chkB],
            chkDCtrl: [this.chkD],
            chkPCtrl: [this.chkP],
            producerCtrl: [this.season.producer],
            directorCtrl: [this.season.directors],
            actorsCtrl: [this.season.actors],
            year_of_releaseCtrl: [this.season.year_of_release],
            parentalGCtrl: [this.parentalG, Validators.required],
        });


        this.rightsFormGroup = this.formBuilder.group({
            distributorsCtrl: [this.distributors],
            internalCategoryCtrl: [this.internalCategory],
            targetDemographicCtrl: [this.targetDemographic],
            costPerEpisodeCtrl: [this.rightsManagement.costPerEpisode],
            costPerEpisode_InRandCtrl: [this.rightsManagement.costPerEpisode_InRand],
            scheduleTimeCtrl: [this.scheduleTime],
            start_dateCtrl: [this.rightsManagement.startDate, Validators.required],
            end_dateCtrl: [this.rightsManagement.endDate, Validators.required],
            groupsChannelsCtrl: [this.rightsManagement.groupsChannels, Validators.required],
            regionCtrl: [this.rightsManagement.region],
            typesOfRightsCtrl: [this.rightsManagement.typesOfRights],
            mediaCtrl: [this.rightsManagement.media],
            languageCtrl: [this.rightsManagement.language],
            platformCtrl: [this.rightsManagement.platform],
            typeOfRightsCtrl: [this.rightsManagement.typesOfRights],
            numberOfPlaysCtrl: [this.rightsManagement.numberOfPlays],
            totalTxPerEpCtrl: [this.rightsManagement.totalTxPerEp],
            exclusiveCtrl: [this.rightsManagement.exclusive],
            unlimitedPlaysCtrl: [this.rightsManagement.unlimitedPlays],
            unlimitedTxPerEpiCtrl: [this.rightsManagement.unlimitedTxPerEpi],
            currencyCtrl: [this.rightsManagement.currency],
            totalEpisodesCtrl: [this.rightsManagement.totalEpisodes],
            deliveryExpensesCtrl: [this.rightsManagement.deliveryExpenses],
            totalAmountCtrl: [this.rightsManagement.totalAmount],
            boughtAtCtrl: [this.rightsManagement.boughtAt],
        });
  
  
        for(var i=0;i<this.cur_obj.length;i++){
          this.currency[i] = this.cur_obj[i].cc+' - '+this.cur_obj[i].name;
      }
    }


    addSeason(){

    }

    openSidebar(){
        this.licence_fee_Sidebar = true;
        /*this.rightsManagement.totalEpisodes = 0;
        for (let i = 0; i < this.programmes.length; i++) {
            if ( this.programmes[i].episodes != null){
                this.rightsManagement.totalEpisodes = this.rightsManagement.totalEpisodes + this.programmes[i].episodes; 
            }   
        }*/
      }
    
      onTxsChange(){
        if(this.rightsManagement.unlimitedTxPerEpi){
            this.rightsManagement.totalTxPerEp = 0;
        }
      }
    
      onPlaysChange(){
        if(this.rightsManagement.unlimitedPlays){
            this.rightsManagement.numberOfPlays = 0;
        }
      }

      currecyCode(currency){

        let arrayData = currency.split(" - ");
        this.currency_code = arrayData[0];
      }
    
      calculateAmount(){
    
        if(this.rightsManagement.totalEpisodes == null){this.rightsManagement.totalEpisodes = 0;}
        if(this.rightsManagement.deliveryExpenses == null){this.rightsManagement.deliveryExpenses = 0;}
        if(this.rightsManagement.boughtAt == null || this.currency_code == 'ZAR'){this.rightsManagement.boughtAt = 1;}
        if(this.rightsManagement.costPerEpisode == null){this.rightsManagement.costPerEpisode = 0;}
        if(this.rightsManagement.costPerEpisode_InRand == null){this.rightsManagement.costPerEpisode_InRand = 0;}
        
        this.rightsManagement.totalAmount = ((this.rightsManagement.costPerEpisode * this.rightsManagement.totalEpisodes) + (this.rightsManagement.totalEpisodes * this.rightsManagement.deliveryExpenses)) * this.rightsManagement.boughtAt;
        
        if(this.rightsManagement.totalAmount > 0 && this.rightsManagement.totalEpisodes > 0){this.rightsManagement.costPerEpisode_InRand = this.rightsManagement.totalAmount/this.rightsManagement.totalEpisodes;}
      }


    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SeasonClass): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
    }

    //Apply filter when using search box
    applyFilter(filterValue: string) {
        this.selection.clear();
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    async onReadProgrammes() {

        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
            {
                channes: this.selected_channel,
                employee_no: this.employee_no,
                prog_no: this.programme.prog_no,

            }, {responseType: 'json'}).pipe()
            .subscribe(
                (data: any) => {

                    this.selected_channel = Number(data[0].sare_no);
                    this.contract.contract_no = data[0].contract_no;
                    this.programme.prog_name = data[0].prog_name;
                    this.programme.prod_house = data[0].prod_house;
                    this.contract.prod_partner = data[0].prod_partner;
                    this.programme.length = data[0].length;
                    this.programme.genre = data[0].genre;
                    this.contract.contract_type = data[0].contract_type;
                    this.programme.language = data[0].language;
                    this.programme.image = data[0].image;
                    this.programme.subgenre = data[0].subgenre;
                    this.programme.category = data[0].category;
                    this.programme.genre_sentech = data[0].genre_sentech;
                    this.programme.subgenre_sentech = data[0].subgenre_sentech;
                    this.distributors = data[0].distributorID;

                    this.selected_subgenries = this.subgenries;
                    this.selected_sentech_subgenries = this.sentech_subgenries;

                    this.onReadProgOptions();
                    this.onReadProgSeasons();
                }
            )
    }

    onFileSelected(event) {
        this.selectedFile = <FileList>event.target.files;
        this.alertService.success('Image(s) successfully selected');
    }

    async onReadProgSeasons() {

        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
        {
            employee_no: this.employee_no,
            prog_no: this.programme.prog_no,

        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {

                this.display_array = [];

                for (var i = 0; i < data.length; i++) {
                    this.display_array[i] = {
                        actors: data[i].actors,
                        directors: data[i].directors,
                        producer: data[i].producer,
                        cost_per_min: data[i].cost_per_min,
                        end_date: data[i].end_date,
                        episodes: data[i].episodes,
                        exhibition_no: data[i].exhibition_no,
                        idNo: data[i].idNo,
                        prog_name: data[i].prog_name,
                        prog_no: data[i].prog_no,
                        run_no: data[i].run_no,
                        seas_no: data[i].seas_no,
                        segments: data[i].segments,
                        sst_date: data[i].sst_date,
                        year_of_release: data[i].year_of_release,
                        censorship: data[i].censorship,
                        pg: data[i].pg,
                        synopsis: data[i].synopsis,
                        hashtags: data[i].hashtags
                    };
                }

                this.dataSource = new MatTableDataSource(this.display_array);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;

            }
        )
    }


    openDeleteDialog(): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: 'Are you sure you want to delete selected seasons ?'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.selection.selected.forEach(season => {
                    this.deleteSeason(season.seas_no);
                });

                this.selection.clear();
            }
        });
    }

    deleteSeason(seas_no) {
        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/delete_season.php`,
            {
                seas_no: seas_no,
                prog_no: this.programme.prog_no

            }, {responseType: 'json'}).pipe()
            .subscribe(
                (data: any) => {

                    if (data.message == 'success') {
                        this.alertService.success('Season successfully deleted');
                        this.onReadProgSeasons();
                    } else {
                        this.alertService.error(data.message);
                    }
                }
            )
    }

    async createProgramme() {
        
        this.formData = new FormData();

        if(this.season.directors == null){this.season.directors = ' ';}
        if(this.season.actors == null){this.season.actors = ' ';}
        if(this.season.producer == null){this.season.producer = ' ';}
        if(this.contract.prod_partner == null){this.contract.prod_partner = ' ';}
        if(this.programme.prod_house == null){this.programme.prod_house = ' ';}
        if(this.contract.contract_no == null){this.contract.contract_no = ' ';}
        if(this.programme.genre == null){this.programme.genre = ' ';}
        if(this.programme.subgenre == null){this.programme.subgenre = ' ';}
        if(this.programme.category == null){this.programme.category = ' ';}
        if(this.programme.genre_sentech == null){this.programme.genre_sentech = ' ';}
        if(this.programme.subgenre_sentech == null){this.programme.subgenre_sentech = ' ';}
        if(this.contract.contract_type == null){this.contract.contract_type = ' ';}
        if(this.genericSynopsis == null){this.genericSynopsis = ' ';}
        if(this.season.producer == null){this.season.producer = ' ';}
        if(this.season.directors == null){this.season.directors = ' ';}
        if(this.season.actors == null){this.season.actors = ' ';}
        if(this.display_array == null){this.display_array = [];}


        if (this.selectedFile != null) {
            for (var x = 0; x < this.selectedFile.length; x++) {
                this.formData.append('fileToUpload[]', this.selectedFile[x], this.selectedFile[x].name);
            }
        }

        this.formData.append('employee_no', <any>this.employee_no);
        this.formData.append('prog_no', <any>this.programme.prog_no);
        this.formData.append('chann', <any>this.selected_channel);
        this.formData.append('contract', <any>this.contract.contract_no);
        this.formData.append('prog_name', <any>this.programme.prog_name);
        this.formData.append('prod_house', <any>this.programme.prod_house);
        this.formData.append('prod_partner', <any>this.contract.prod_partner);
        this.formData.append('prog_length', <any>this.programme.length);
        this.formData.append('genre', <any>this.programme.genre);
        this.formData.append('contract_type', <any>this.contract.contract_type);
        this.formData.append('language', <any>this.programme.language);
        this.formData.append('subgenre', <any>this.programme.subgenre);
        this.formData.append('category', <any>this.programme.category);
        this.formData.append('genre_sentech', <any>this.programme.genre_sentech);
        this.formData.append('subgenre_sentech', <any>this.programme.subgenre_sentech);
        this.formData.append('distributorID', <any>this.distributors);

        this.display_array.forEach((item, index) => {
            Object.keys(item).forEach(key => {
              const value = item[key];
              this.formData.append(`display_array[${index}][${key}]`, value);
            });
          });


        this.httpClient.post('https://capetowntv.mmsafrica.co.za/api/schedule/programme/edit_programme.php', this.formData, {responseType: 'json'})
            .subscribe((data: any) => {


                if (data.message == 'success') {
                    this.alertService.success('Programme successfully saved');
                    this.selectedFile = null;
                } else {
                    this.alertService.error(data.message);
                }

            });
    }

    async createSeason() {

        if(this.season.directors == null){this.season.directors = ' ';}
        if(this.season.actors == null){this.season.actors = ' ';}
        if(this.season.producer == null){this.season.producer = ' ';}
        if(this.contract.prod_partner == null){this.contract.prod_partner = ' ';}
        if(this.programme.prod_house == null){this.programme.prod_house = ' ';}
        if(this.contract.contract_no == null){this.contract.contract_no = ' ';}
        if(this.programme.genre == null){this.programme.genre = ' ';}
        if(this.programme.subgenre == null){this.programme.subgenre = ' ';}
        if(this.programme.category == null){this.programme.category = ' ';}
        if(this.contract.contract_type == null){this.contract.contract_type = ' ';}
        if(this.genericSynopsis == null){this.genericSynopsis = ' ';}
        if(this.season.producer == null){this.season.producer = ' ';}
        if(this.season.directors == null){this.season.directors = ' ';}
        if(this.season.actors == null){this.season.actors = ' ';}

        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/create_programme.php`,
            {

                employee_no: this.employee_no,
                epi_no: this.season.episodes,
                epi_seg: this.season.segments,
                prog_no: this.programme.prog_no,
                sea_no: this.season.seas_no,
                start_date: moment(this.season.sst_date.toString()).format("YYYY-MM-DD"),
                end_date: moment(this.season.end_date.toString()).format("YYYY-MM-DD"),
                producer: this.season.producer,
                synopsis: this.genericSynopsis,
                actors: this.season.actors,
                directors: this.season.directors,
                year_of_release: this.season.year_of_release,
                defaultTitle: this.defaultTitle,
                chkLN: this.chkLN,
                chkN: this.chkN,
                chkS: this.chkS,
                chkV: this.chkV,
                chkB: this.chkB,
                chkD: this.chkD,
                chkP: this.chkP,

                chann: this.selected_channel,
                contract: this.contract.contract_no,
                prog_name: this.programme.prog_name,
                prod_house: this.programme.prod_house,
                prod_partner: this.contract.prod_partner,
                prog_length: this.programme.length,
                genre: this.programme.genre,
                contract_type: this.contract.contract_type,
                language: this.programme.language,
                parental_g: this.parentalG,
                subgenre: this.programme.subgenre,
                category: this.programme.category,

            }, {responseType: 'json'}).pipe()
            .subscribe(
                (data: any) => {

                    if (data.message == 'success') {
                        this.seasonFormGroup.reset();
                        this.season = new SeasonClass();
                        this.alertService.success('Programme successfully saved');
                        this.programme.prog_no = data.prog_no;

                        this.season.seas_no = null;
                        this.season.episodes = null;
                        this.season.segments = null;
                        this.genericSynopsis = null;
                        this.chkLN = false;
                        this.chkN = false;
                        this.chkV = false;
                        this.chkB = false;
                        this.chkD = false;
                        this.chkP = false;
                        this.season.producer = null;
                        this.season.directors = null;
                        this.season.actors = null;
                        this.parentalG = null;

                        this.onReadProgSeasons();
                    } else {
                        this.alertService.error(data.message);
                    }
                }
            )
    }


    viewSeason(season) {
        localStorage.setItem('prog_no', JSON.stringify(this.programme.prog_no));
        localStorage.setItem('season_no', JSON.stringify(season));
        this.router.navigate(['/home/view-season']);
    }

    genreChanged(idNO) {

        console.log('Genre: '+idNO);
        this.selected_subgenries = [];
 
        for (var i = 0; i < this.subgenries.length; i++) {
            if (this.subgenries[i].genre == idNO) {
                this.selected_subgenries.push(this.subgenries[i]);
            }
        }
    }

    sentechGenreChanged(idNO) {

        this.selected_sentech_subgenries = [];
 
        for (var i = 0; i < this.sentech_subgenries.length; i++) {
            if (this.sentech_subgenries[i].genre == idNO) {
                this.selected_sentech_subgenries.push(this.sentech_subgenries[i]);
            }
        }
    }


    async onReadRightsManagement() {

        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/read_prog_rightsmanagement.php`,
            {
                channes: this.selected_channel,
                prog_no: this.programme.prog_no,
  
            }, {responseType: 'json'}).pipe()
            .subscribe(
                (data: any) => {

                    this.distributors = data[0].distributors;
                    this.targetDemographic = data[0].targetDemographic;
                    this.rightsManagement.costPerEpisode = data[0].costPerEpisode;
                    this.rightsManagement.costPerEpisode_InRand = data[0].costPerEpisode_InRand;
                    this.rightsManagement.startDate = data[0].sst_date;
                    this.rightsManagement.endDate = data[0].end_date;
                    this.rightsManagement.groupsChannels = this.selected_channel;
                    this.rightsManagement.region= data[0].region.split(";");;
                    this.rightsManagement.typesOfRights = data[0].typesOfRights;
                    this.rightsManagement.media = data[0].media;
                    this.rightsManagement.language = data[0].language;
                    this.rightsManagement.platform = data[0].platform;
                    this.rightsManagement.numberOfPlays = Number(data[0].no_of_runs);
                    this.rightsManagement.totalTxPerEp = Number(data[0].totalTxPerEp);
                    this.rightsManagement.exclusive = Boolean(data[0].exclusive);
                    this.rightsManagement.unlimitedPlays = Boolean(data[0].unlimitedPlays);
                    this.rightsManagement.unlimitedTxPerEpi = Boolean(data[0].unlimitedTxPerEpi);
                    this.rightsManagement.currency = data[0].currency;
                    this.rightsManagement.totalEpisodes = Number(data[0].totalEpisodes);
                    this.rightsManagement.deliveryExpenses = Number(data[0].deliveryExpenses);
                    this.rightsManagement.totalAmount = Number(data[0].totalAmount);
                    this.rightsManagement.boughtAt = Number(data[0].boughtAt);

                    let arrayData = data[0].currency.split(" - ");
                    this.currency_code = arrayData[0];

                    this.season.sst_date = data[0].sst_date;
                    this.season.end_date = data[0].end_date;

                }
            )
    }

    async saveRightsManagement() {

        let regions = "";

        for(var i=0;i < this.rightsManagement.region.length;i++){
            regions = regions+this.rightsManagement.region[i]+";";
        }
        
    const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/edit_rights_management.php`,
    {
        prog_no: this.programme.prog_no,
        distributors: this.distributors,
        targetDemographic: this.targetDemographic,
        costPerEpisode: this.rightsManagement.costPerEpisode,
        costPerEpisode_InRand: this.rightsManagement.costPerEpisode_InRand,
        startDate: moment(this.rightsManagement.startDate.toString()).format('YYYY-MM-DD'),
        endDate: moment(this.rightsManagement.endDate.toString()).format('YYYY-MM-DD'),
        groupsChannels : this.rightsManagement.groupsChannels,
        region: regions,
        typesOfRights : this.rightsManagement.typesOfRights,
        media : this.rightsManagement.media,
        language : this.rightsManagement.language,
        platform : this.rightsManagement.platform,
        numberOfPlays : this.rightsManagement.numberOfPlays,
        totalTxPerEp : this.rightsManagement.totalTxPerEp,
        exclusive : this.rightsManagement.exclusive,
        unlimitedPlays : this.rightsManagement.unlimitedPlays,
        unlimitedTxPerEpi : this.rightsManagement.unlimitedTxPerEpi,
        currency : this.rightsManagement.currency,
        totalEpisodes : this.rightsManagement.totalEpisodes,
        deliveryExpenses : this.rightsManagement.deliveryExpenses,
        totalAmount : this.rightsManagement.totalAmount,
        boughtAt : this.rightsManagement.boughtAt

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            if (data.message == 'success') {
                this.alertService.success('Programme rights successfully saved');

            } else {
                this.alertService.error(data.message);
            }

        }
    )
}

    async onReadProgOptions() {


        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/programme/get_programme_options.php`,
            {
                employee_no: this.employee_no,
  
            }, {responseType: 'json'}).pipe()
            .subscribe(
                (data: any) => {
  
  
                  for (let i = 0; i < data[0].distributors.length; i++) {
                      this.distributors_array[i] = {
                          idNo: data[0].distributors[i].idNo,
                          distributor: data[0].distributors[i].distributor,
                          contact_person: data[0].distributors[i].contact_person,
                          phone: data[0].distributors[i].phone,
                          email: data[0].distributors[i].email
                      };
                  }

                  for (let i = 0; i < data[0].subgenries.length; i++) {
                        this.subgenries[i] = {genre: data[0].subgenries[i].genre,
                            idNo: data[0].subgenries[i].idNo,
                            subgenre: data[0].subgenries[i].subgenre,
                            code: 0
                        };
                    }
  
                    for (let i = 0; i < data[0].genre.length; i++) {
                        this.genres[i] = {
                            bdcr_no: data[0].genre[i].bdcr_no,
                            genre: data[0].genre[i].genre,
                            genreId: data[0].genre[i].genreId,
                            color: data[0].genre[i].color,
                            code: 0
                        };

                        if(this.programme.genre == this.genres[i].genre){
                            this.genreChanged(this.genres[i].genreId);
                        }
                        
                    }
  

                   for (let i = 0; i < data[0].sentech_subgenre.length; i++) {
                        this.sentech_subgenries[i] = {genre: data[0].sentech_subgenre[i].genre,
                            idNo: data[0].sentech_subgenre[i].idNo,
                            subgenre: data[0].sentech_subgenre[i].subgenre,
                            code: data[0].sentech_subgenre[i].code
                        };
                    }

                   for (let i = 0; i < data[0].sentech_genre.length; i++) {
                    this.sentech_genres[i] = {
                        bdcr_no: data[0].sentech_genre[i].bdcr_no,
                        genre: data[0].sentech_genre[i].genre,
                        genreId: data[0].sentech_genre[i].genreId,
                        color: data[0].sentech_genre[i].color,
                        code: data[0].sentech_genre[i].code
                    };

                    if(Number(this.programme.genre_sentech) == this.sentech_genres[i].code){
                        this.sentechGenreChanged(this.sentech_genres[i].genreId);
                    }
                    
                }

                    
  
                    for (let i = 0; i < data[0].pgs.length; i++) {
                        this.pgs[i] = {
                            pg_id: data[0].pgs[i].pg_id,
                            code: data[0].pgs[i].code,
                            pg_name: data[0].pgs[i].pg_name
                        };
                    }
  
                    for (let i = 0; i < data[0].language.length; i++) {
                        this.languages[i] = {
                            idNo: data[0].language[i].idNo,
                            bdcr_no: data[0].language[i].bdcr_no,
                            language: data[0].language[i].language
                        };
                    }
  
                    for (let i = 0; i < data[0].contract.length; i++) {
                        this.contractTypes[i] = {
                            contract_id: data[0].contract[i].contract_id,
                            contract: data[0].contract[i].contract
                        };
                    }
  
                    for (let i = 0; i < data[0].user.length; i++) {
                        this.users[i] = {
                            name: data[0].user[i].name,
                            bdcr_no: data[0].user[i].bdcr_no,
                            paswd: data[0].user[i].paswd,
                            email: data[0].user[i].email,
                            role_id: data[0].user[i].role_id,
                            atemps: data[0].user[i].atemps,
                            pers_code: data[0].user[i].pers_code,
                            accountType: data[0].user[i].accountType,
                            phoneNumber: data[0].user[i].phoneNumber,
                            status: data[0].user[i].status,
                            surname: data[0].user[i].surname,
                            analytics_delete: null,
                          analytics_read: null,
                          analytics_write:null,
                          content_management_delete:null,
                          content_management_read:null,
                          content_management_write:null,
                          content_sales_delete:null,
                          content_sales_read:null,
                          content_sales_write:null,
                          elearning_delete:null,
                          elearning_read:null,
                          elearning_write:null,
                          finance_delete:null,
                          finance_read:null,
                          finance_write:null,
                          hr_delete:null,
                          hr_read:null,
                          hr_write:null,
                          inventory_management_delete:null,
                          inventory_management_read:null,
                          inventory_management_write:null,
                          market_place_delete:null,
                          market_place_read:null,
                          market_place_write:null,
                          mat_man_delete:null,
                          mat_man_read:null,
                          mat_man_write:null,
                          news_delete:null,
                          news_read:null,
                          news_write:null,
                          production_services_delete:null,
                          production_services_read:null,
                          production_services_write:null,
                          prog_delete:null,
                          prog_read:null,
                          prog_write:null,
                          project_management_delete:null,
                          project_management_read:null,
                          project_management_write:null,
                          revenue_management_delete:null,
                          revenue_management_read:null,
                          revenue_management_write:null,
                          schedule_delete:null,
                          schedule_read:null,
                          schedule_write:null,
                          web2app_delete:null,
                          web2app_read:null,
                          web2app_write:null
                        };
                    }
  
                    for (let i = 0; i < data[0].categories.length; i++) {
                        this.categories[i] = {idNo: data[0].categories[i].idNo, name: data[0].categories[i].name};
                    }
  
  
                    for (let i = 0; i < data[0].regions.length; i++) {
                        this.regions[i] = {reg_Id: data[0].regions[i].idNo,name: data[0].regions[i].name
                        };
                    }
  
  
                    for (let i = 0; i < data[0].type_of_rights.length; i++) {
                      this.type_of_rights[i] = {
                          idNo: data[0].type_of_rights[i].contract_id,name: data[0].type_of_rights[i].contract
                      };
                  }
  
  
                  for (let i = 0; i < data[0].media_types.length; i++) {
                      this.media_types[i] = {
                          idNo: data[0].media_types[i].idNo,name: data[0].media_types[i].name
                      };
                  }
  
                  for (let i = 0; i < data[0].demographics.length; i++) {
                      this.demographics_array[i] = {
                          audienceId: data[0].demographics[i].audienceId,bdcr_no: data[0].demographics[i].bdcr_no,defaultRating: data[0].demographics[i].defaultRating,target_audience: data[0].demographics[i].target_audience,totals: data[0].demographics[i].totals
                      };
                  }
  
                }
            );
    }

    async onChannels() {

        const response = this.httpClient.post(`https://capetowntv.mmsafrica.co.za/api/schedule/get_channels.php`,
            {
                channes: this.selected_channel,
                date: moment().format("YYYY/MM/DD"),
                employee_no: this.employee_no,

            }, {responseType: 'json'}).pipe()
            .subscribe(
                (data: any) => {

                    for (var i = 0; i < data.length; i++) {
                        this.channels[i] = {
                            bdcr_no: data[i].bdcr_no,
                            genre: data[i].genre,
                            platform: data[i].platform,
                            sare_name: data[i].sare_name,
                            sare_no: Number(data[i].sare_no)
                        };
                    }

                    if (data.length >= 1) {
                        this.selected_channel = Number(this.channels[0].sare_no);
                    }
                }
            )
    }

    back() {
        this._location.back();
    }
}
