import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {User} from '../../models/user';
import {AlertService} from '../alert/alert.service';
import {environment} from '../../../environments/environment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM-YYYY',
  }, 
  display: {
    dateInput: 'MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

@Injectable()
export class AuthenticationService {

  constructor(private http: HttpClient, private alertService: AlertService) {
    
  }

  currentUser: User;  

  signIn(username: string, password: string): Observable<User> {

    //https://capetowntv.mmsafrica.co.za/api/admin/login_ldap.php
    return this.http.get<any>(`https://capetowntv.mmsafrica.co.za/api/admin/login.php?username=` + username + '&password=' + password)
      .pipe(map(user => { 
          // login successful if there's a jwt token in the response
          if (user && user.userid) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const mappedUser = this.mapUser(user);
            localStorage.setItem('currentUser', JSON.stringify(mappedUser));


            //Ndango Variables for system intergration verification
            var time = moment().format("YYYY-MM-DD H:mm:ss");
            localStorage.setItem('employee_no', JSON.stringify(mappedUser.userId));
            localStorage.setItem('hr_employee_no', JSON.stringify(mappedUser.userId));
            localStorage.setItem('currentUser', JSON.stringify(mappedUser.userId));
            localStorage.setItem('userId', JSON.stringify(mappedUser.userId));
            localStorage.setItem('hr_employee_no_me', JSON.stringify(mappedUser.userId));
            localStorage.setItem('database_name', JSON.stringify('mmsafric_nbc'));
            localStorage.setItem('company', JSON.stringify('')); 
            localStorage.setItem('names', JSON.stringify(mappedUser.name));
            localStorage.setItem('email', JSON.stringify(mappedUser.email));
            localStorage.setItem('role', JSON.stringify(mappedUser.role));
            localStorage.setItem('last_update', JSON.stringify(''));
            localStorage.setItem('time_in', JSON.stringify(time));
            localStorage.setItem('sys_accountId', JSON.stringify(24));
            localStorage.setItem('chart_type', JSON.stringify("pie"));
            localStorage.setItem('theme_color', JSON.stringify("#292f4c"));
 

            return this.mapUser(user);
          } else {
            this.alertService.error(user); 
          }
        }, 
        error => {
          this.alertService.error("Something went wrong, Try again");
        }));
  }

  signMock(username: string, password: string): Observable<User> {
    this.currentUser = new User(5321738, 'Abdul', 10, 'demo@liink.co.za', 1,'Admin');

    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));

    return of(this.currentUser);
  }

  mapUser(userData) {
    return new User(userData.userid, userData.name, userData.bdcr_no, userData.email, userData.role_id,userData.accountType);

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
  }
}
